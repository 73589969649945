import { ReactElement } from 'react';
import { Icon } from 'components/Icon';
import { Link } from 'react-router-dom';
import { useMembership } from 'services';

interface InvoiceSourceProps {
  className?: string;
  source_avb?: number | null;
  source_campaign?: number | null;
  source_cvb?: number | null;
}

export function InvoiceSource({
  source_campaign,
  source_avb,
  source_cvb,
  className,
}: InvoiceSourceProps): ReactElement | null {
  const { slug } = useMembership();
  if (source_campaign) {
    return (
      <Link to={`/${slug}/planning/campaigns/${source_campaign}/planning`} className={className} title="Campaign">
        <Icon icon="paper-plane" />
      </Link>
    );
  }
  if (source_avb) {
    return (
      <Link to={`/${slug}/guarantee/avb/${source_avb}`} className={className} title="AVB">
        <Icon icon="handshake" />
      </Link>
    );
  }
  if (source_cvb) {
    return (
      <Link to={`/${slug}/guarantee/cvb/${source_cvb}`} className={className} title="CVB">
        <Icon icon="handshake" />
      </Link>
    );
  }
  return null;
}
