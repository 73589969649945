import { Button } from '@ff-it/ui';
import cx from 'clsx';
import { Icon, IconProp } from '../Icon';
import { ComponentProps, ReactElement } from 'react';

export interface Action {
  action: string;
  button?: Partial<ComponentProps<typeof Button>> & { icon?: IconProp };
}

export interface ActionsProps {
  className?: string;
  disabled?: boolean;
  actions: Action[];
  group?: boolean;
}

export function Actions({ className, actions, disabled, group = false }: ActionsProps): ReactElement {
  return (
    <div className={cx('actions', { 'btn-group': group }, className)}>
      {actions.map(({ button: { icon, children, className: btnClassName, ...button } = {}, action }, idx) => (
        <Button
          type="button"
          key={action}
          data-test-id={`action-${action}`}
          className={cx(idx === 0 || group ? undefined : 'ml-1', btnClassName)}
          disabled={disabled}
          {...button}
        >
          {children} {icon && <Icon icon={icon} className="ml-1" />}
        </Button>
      ))}
    </div>
  );
}
