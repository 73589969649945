import { createColumn } from './createColumn';
import type { ReactNode } from 'react';
import { RenderCellProps, RenderEditCellProps, SelectEditor } from 'components/DataGrid';
import { GridRow } from '../types';
import { getUnitOptions } from 'options';
import { useEntity } from 'components';
import { BlockDetails } from 'modules/campaign/block/types';
import { Box } from '@ff-it/ui';
import { temporalUnits } from 'types';
import { CellBagde } from '../CellBadge';

function Edit(props: RenderEditCellProps<GridRow, any>): ReactNode {
  const type = useEntity<BlockDetails>().item.type;
  return <SelectEditor {...props} options={getUnitOptions(props.row.kind, type)} isSimple isSearchable />;
}

const content = (
  <Box padding="sm" maxWidth="xs">
    Sold as calendar units.
    <br /> Quantity expressed as time × position quantity
  </Box>
);

function renderView({ row: { kind, ...row } }: RenderCellProps<GridRow, any>): ReactNode {
  if (kind === 'SMART') {
    return row.application;
  }
  return (
    <>
      {row.unit && temporalUnits.includes(row.unit as any) && (
        <CellBagde icon="clock" placement="top-end" title="Sold as calendar units" testId="time-badge" />
      )}
      {row.unit}
    </>
  );
}

export const UnitColumn = createColumn(
  {
    key: 'unit',
    name: 'Unit',
    width: 76,
  },
  {
    renderView,
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
