import { ReactElement, useState, ReactNode, useMemo } from 'react';
import { Submit, useForm } from '@ff-it/form';
import { Box, Button, DialogBody, DialogClose, DialogFooter, DialogHeader } from '@ff-it/ui';
import type { ProductKind } from 'types';
import { Icon } from 'components/Icon';
import { productKindOptions } from 'options';
import { FormProps, Precedence, isComplete } from './types';
import useLocalStorageState from 'use-local-storage-state';
import { ProductFirst } from './ProductFirst';
import { initialState } from './const';
import { ProviderFirst } from './ProviderFIrst';
import { ActivityFirst } from './ActivityFirst';

const titleMap: Record<Precedence, ReactNode> = {
  PRODUCT: 'Product',
  PROVIDER: 'Provider',
};

/**
 * kind - constant kind, or list of selectable options
 * incomplete - select independent supplier fields might be incomplete
 *    this disables precedence
 *
 * FIXME: should probably never default media
 */
export function Form({ type, kind, incomplete, required, department }: FormProps): ReactElement {
  const form = useForm();

  const [currentKind, setKind] = useState<ProductKind>(kind ? (Array.isArray(kind) ? kind[0] : kind) : 'MEDIA');
  const [precedenceState, setPrecedence] = useLocalStorageState<Precedence>('_supplier_picker_precedence', {
    defaultValue: 'PRODUCT',
  });

  const precedence = incomplete ? null : precedenceState;

  const kindOptions: typeof productKindOptions | null = useMemo(() => {
    if (!kind) {
      return productKindOptions;
    }
    if (Array.isArray(kind)) {
      return productKindOptions.filter((o) => kind.includes(o.value));
    }
    return null;
  }, [kind]);

  function onKindChange(kind: ProductKind): void {
    // reset product on kind change
    form.initialize(initialState);
    setKind(kind);
  }

  function onPrecedenceChange(): void {
    if (!isComplete(form.getState().values as any)) {
      form.initialize(initialState);
    }
    setPrecedence((current) => (current === 'PRODUCT' ? 'PROVIDER' : 'PRODUCT'));
  }

  return (
    <>
      <DialogHeader title={incomplete ? 'Supplier fields' : 'Supplier'}>
        <Box marginLeft="auto">
          {kindOptions && (
            <div className="btn-group mr-1">
              {kindOptions.map(({ value, label }) => (
                <Button
                  key={value}
                  variant="outline-primary"
                  size="sm"
                  active={value === currentKind}
                  onClick={() => onKindChange(value)}
                >
                  {label}
                </Button>
              ))}
            </div>
          )}
          {precedence && (
            <Button
              size="sm"
              variant="outline-secondary"
              title={`Precedence - ${precedence}`}
              onClick={onPrecedenceChange}
            >
              {titleMap[precedence]}
              <Icon icon="swap" className="ml-1" />
            </Button>
          )}
        </Box>
      </DialogHeader>
      <DialogBody>
        {(() => {
          switch (precedence) {
            case null: {
              return <ActivityFirst type={type} kind={currentKind} required={required} department={department} />;
            }
            case 'PRODUCT': {
              return <ProductFirst type={type} kind={currentKind} required={required} department={department} />;
            }
            case 'PROVIDER': {
              return <ProviderFirst type={type} kind={currentKind} required={required} department={department} />;
            }
          }
        })()}
      </DialogBody>
      <DialogFooter>
        <Submit>Select</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </>
  );
}
