import { Dispatch, SetStateAction, useState, ReactElement } from 'react';
import { Icon, type IconProp } from '../../Icon';
import { saveAs } from 'file-saver';
import { HTTPMethod, RequestBody } from '@ff-it/api';
import { Button, ButtonProps, Spinner } from '@ff-it/ui';
import { actionErrorOrThrow, parseAttachmentFilename } from 'utilities';
import { api } from 'services';

interface DownloadHandlerProps {
  url: string;
  filename?: string;
  method?: HTTPMethod;
  body?: RequestBody;
  accept?: string;
  queryParams?: any;
  headers?: HeadersInit;
}

interface DownloadButtonProps extends Omit<ButtonProps, 'onClick'>, DownloadHandlerProps {
  title?: string;
  icon?: IconProp;
}

export async function downloadHandler(
  {
    url,
    filename: providedFilename,
    method = 'GET',
    body,
    accept: Accept = '*/*',
    queryParams,
    headers,
  }: DownloadHandlerProps,
  setLoading?: Dispatch<SetStateAction<boolean>>,
): Promise<void> {
  setLoading && setLoading(true);
  const args = {
    url,
    method,
    body,
    queryParams,
    headers: {
      Accept,
      ...headers,
    },
  };

  const result = await api.request<Blob, unknown>(args);

  setLoading && setLoading(false);
  if (result.ok) {
    saveAs(result.data, providedFilename || parseAttachmentFilename(result.response.headers));
  } else {
    actionErrorOrThrow(result);
  }
}

export function useDownloadHandler(
  props: DownloadHandlerProps,
  setLoading?: Dispatch<SetStateAction<boolean>>,
): () => Promise<void> {
  const [err, setError] = useState<any>();
  if (err) {
    throw err;
  }
  return async () => {
    try {
      await downloadHandler(props, setLoading);
    } catch (error) {
      setError(error);
    }
  };
}

export function DownloadButton({
  url,
  filename,
  method,
  body,
  icon,
  children,
  title,
  accept,
  queryParams,
  ...props
}: DownloadButtonProps): ReactElement {
  const [loading, setLoading] = useState(false);

  const onClick = useDownloadHandler(
    {
      url,
      filename,
      method,
      body,
      accept,
      queryParams,
    },
    setLoading,
  );

  return (
    <Button onClick={onClick} {...props} disabled={loading}>
      {children}{' '}
      {loading ? (
        <Spinner size="sm" className="ml-1" aria-hidden={true} />
      ) : icon ? (
        <Icon className={children ? 'ml-1' : undefined} icon={icon} title={title} />
      ) : null}
    </Button>
  );
}
