import { Box, Popover } from '@ff-it/ui';
import { DepartmentFactor } from './types';
import { FactorRateBadge } from './FactorRateBadge';
import { nl2br } from 'utilities';
import { descriptionContainer, descriptionMoreIcon, nameContainer } from './FactorCard.css';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'components/Icon';

function Description({ description }: { description: string }) {
  // FIXME: really unfortunate that we have to measure the div
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current?.scrollWidth > descriptionRef.current?.clientWidth);
    }
  }, [description]);

  const content = (
    <div className={descriptionContainer} ref={descriptionRef}>
      {description}
    </div>
  );

  if (isOverflowing) {
    return (
      <div className={descriptionContainer} ref={descriptionRef}>
        {description}
        <Popover
          placement="bottom-start"
          interaction="click"
          content={
            <Box padding="md" fontSize="xs" maxWidth="sm">
              {nl2br(description)}
            </Box>
          }
        >
          <div className={descriptionMoreIcon}>
            <Icon icon="circle-question" />
          </div>
        </Popover>
      </div>
    );
  }
  return content;
}
export function FactorCard({ factor, className }: { factor: DepartmentFactor; className?: string }) {
  const { id, name, description } = factor;

  return (
    <Box date-testid={`factor-${id}`} fontSize="md" className={className}>
      <Box display="flex">
        <Box date-testid="factor-name" className={nameContainer}>
          {name}
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="flex-start" justifyContent="center">
          <FactorRateBadge factor={factor} portal />
        </Box>
      </Box>
      {description && <Description description={description} />}
    </Box>
  );
}
