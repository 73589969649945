import { ReactElement } from 'react';
import { Badge, useEntity, EntityActions, TabNav } from 'components';
import { bookingActions } from 'modules/invoicing/expenses';
import permissions from 'modules/invoicing/expenses/permissions';
import { PaymentStateBadge, InvoiceSource, useNavLinks } from 'modules/invoicing/common';
import { useSessionScope } from 'services';
import type { Expense } from 'modules/invoicing/expenses/types';
import { Icon } from 'components/Icon';

export function Nav(): ReactElement {
  const { item } = useEntity<Expense>();
  const nav = useNavLinks(item, permissions);
  const {
    external,
    state,
    partner,
    number,
    sums: { paym_state },
  } = item;
  const scope = useSessionScope();

  return (
    <div className="container-fluid header header--expense">
      <div className="d-flex flex-row align-items-center my-2">
        <h2 className="mt-0">
          <InvoiceSource {...item} className="mr-1" />
          <strong>{partner.title}</strong>
          <small className="text-muted ml-2">
            #{number} <PaymentStateBadge state={paym_state} />
          </small>
        </h2>
        <Badge className="ml-2" state={state} />

        <div className="ml-0 mr-auto px-3 mt-auto mb-2">
          {item.attachments?.map(({ file, filename, id }) => (
            <a key={id} href={file} target="_blank" rel="noopener noreferrer" className="badge badge-light mr-1">
              <Icon icon="file-arrow-down" /> {filename}
            </a>
          ))}
        </div>
        {state !== 'CANCELED' && (
          <div className="btn-toolbar ml-auto">
            {(scope.accounting_integration !== 'horizon' || external) && <EntityActions actions={bookingActions} />}
          </div>
        )}
      </div>
      <TabNav nav={nav} />
    </div>
  );
}
