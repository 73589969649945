import { useState, type ReactElement } from 'react';
import { Button, Spinner } from '@ff-it/ui';
import { useModel } from 'components';
import { Icon } from 'components/Icon';
import { fetcher, useSessionScope } from 'services';
import { toast } from 'react-toastify';
import { useTable } from 'components/ControlledTable';

export default function AccountingSyncButton(): ReactElement | null {
  const { accounting_integration } = useSessionScope();
  const [loading, setLoading] = useState(false);
  const model = useModel();
  const { revalidate } = useTable();
  if (accounting_integration !== 'horizon') {
    return null;
  }

  const onClick = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await fetcher({
        url: `${model.endpoint}accounting_sync/`,
        method: 'POST',
      });
      toast.success('Synchronized successfully');
    } catch (error: any) {
      toast.warn('Synchronization failed');
    }
    revalidate();
    setLoading(false);
  };

  return (
    <Button className="mr-1" disabled={loading} size="sm" variant="outline-secondary" onClick={onClick}>
      Synchronize
      {loading && <Spinner size="sm" className="ml-1" aria-hidden={true} />}
      {!loading && <Icon className="ml-1" icon="book-open" />}
    </Button>
  );
}
