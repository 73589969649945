import { ReactElement } from 'react';
import { UserField, LanguageField } from 'components';
import { Icon } from 'components/Icon';
import { TextField, DecimalField, DatePickerField, DateRangePickerField } from '@ff-it/form';
import { addDays, parseISO } from 'date-fns';
import { useField } from 'react-final-form';
import { parseDate, identity, coerceEmptyToNull } from 'utilities';
import { FieldsProps } from './types';
import { ActivityType } from 'types';
import useSWR from 'swr';

function TargetGroups({
  type,
  className,
  required,
}: { type: ActivityType; className?: string; required: boolean }): ReactElement {
  const { data } = useSWR(
    {
      url: `campaign/campaigns/target_groups/${type}/`,
      method: 'GET',
    },
    { fallback: [] as string[] },
  );

  return (
    <TextField
      name="target_groups"
      label="Target groups"
      className={className}
      datalist={data}
      required={required}
      autoComplete="off"
    />
  );
}

export function DeadlineWarning({
  date_from,
  deadline,
}: {
  date_from: string | null;
  deadline: string | null;
}): ReactElement | null {
  if (deadline) {
    const d = parseDate(deadline);
    if (d) {
      const from = date_from ? parseDate(date_from) : undefined;
      if (from && from < d) {
        return (
          <div className="invalid-feedback text-warning d-block">
            <Icon icon="triangle-exclamation" /> Deadline after start of period
          </div>
        );
      }
      if (d < addDays(new Date(), 3)) {
        return (
          <div className="invalid-feedback text-warning d-block">
            <Icon icon="triangle-exclamation" /> Deadline in less than 3 days
          </div>
        );
      }
    }
  }
  return null;
}

function FormDeadlineWarning(): ReactElement | null {
  const {
    input: { value: date_from },
  } = useField('date_from', { subscription: { value: true } });
  const {
    input: { value: deadline },
  } = useField('deadline', { subscription: { value: true } });

  return <DeadlineWarning deadline={deadline} date_from={date_from} />;
}

export function CommonFields({ campaign: { date_from, date_to, client_po_number }, type }: FieldsProps): ReactElement {
  const campaignPeriod = { minDate: parseISO(date_from), maxDate: parseISO(date_to) };

  return (
    <>
      <div className="form-row">
        <TextField name="title" label="Title" parse={identity} className="col-md-7" />
        <TextField
          name="client_po_number"
          label="PO number"
          className="col-md-5"
          {...coerceEmptyToNull}
          help={client_po_number ? `Leave empty to use: ${client_po_number}` : undefined}
        />
      </div>
      <div className="form-row">
        <UserField
          name="planners"
          label="Planners"
          className="col-md-12"
          required
          isMulti
          defaultOptions
          filter={{ plan_campaign_block: true }}
        />
      </div>
      {/* FIXME: this seeems all over the place */}
      {type !== 'SEO' && (
        <div className="form-row">
          <TargetGroups type={type} required={type !== 'CREATIVE'} className="col-md-8" />
          <DecimalField label="Target group size" className="col-md-4" name="target_group_size" precision={2} />
        </div>
      )}
      <div className="form-row">
        <DateRangePickerField
          fromName="date_from"
          toName="date_to"
          label="Period "
          className="col-md-8"
          required
          {...campaignPeriod}
        />
        <DatePickerField
          name="deadline"
          label="Deadline"
          className="col-md-4"
          required
          help={<FormDeadlineWarning />}
          popperPlacement="bottom-end"
        />
      </div>
      <div className="form-row">
        <DecimalField
          name="budget"
          label="Budget"
          className="col-md-6"
          required
          help={
            <>
              Total EUR (incl. prod., fee, etc.) <strong>w/o</strong> VAT
            </>
          }
        />
        <LanguageField name="languages" label="Languages" className="col-md-6" required isMulti />
      </div>
    </>
  );
}
