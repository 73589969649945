import { createContext, useContext } from 'react';
import type { PlanFactor } from 'modules/campaign/block/types';
import invariant from 'tiny-invariant';

const PlanFactorsContext = createContext<PlanFactor[] | undefined>(undefined);

export const PlanFactorsProvider = PlanFactorsContext.Provider;

export function usePlanFactors(): PlanFactor[] {
  const factors = useContext(PlanFactorsContext);
  invariant(factors);
  return factors;
}
