import type { ReactElement, ReactNode } from 'react';
import type { Format } from 'modules/campaign/row';
import { createColumn } from './createColumn';
import { wrapContainer } from './style.css';
import { EditableChoiceEditor, RenderEditCellProps } from 'components/DataGrid';
import { GridRow, GridSummaryRow } from '../types';
import { usePlanController } from '../../usePlanController';
import { Box, Button, DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps, openModal } from '@ff-it/ui';
import { FormatForm } from 'modules/supplier/products/rtb';
import { DialogForm } from 'components';
import { MaterialType } from 'modules/core/types';
import { Form } from '@ff-it/form';
import { Icon, type IconProp } from 'components/Icon';

export const getOptionLabel = (v: null | Format): any => (v ? v.size : v);
export const getOptionValue = (v: null | Format): any => (v ? `${v.material_type}:${v.size}:${v.title}` : v);

function renderValues(value: Format[] | null): ReactNode {
  const text = value && value.length > 0 ? value.map(getOptionLabel).join(', ') : null;
  return text ? (
    <span className={wrapContainer} title={text}>
      {text}
    </span>
  ) : null;
}

const ICONS: Record<MaterialType, IconProp> = {
  IMAGE: 'image',
  AUDIO: 'volume-high',
  TEXT: 'text',
  VIDEO: 'video',
  HTML5: 'file-code',
};

type ViewDialogProps = RenderProps & {
  initialValues: any;
};

export function ViewDialog({ onSubmit, initialValues }: ViewDialogProps): ReactElement {
  return (
    <Form noValidate onSubmit={onSubmit} initialValues={initialValues} disabled>
      <DialogHeader title="Format" />
      <DialogBody>
        <FormatForm />
      </DialogBody>
      <DialogFooter>
        <DialogClose />
      </DialogFooter>
    </Form>
  );
}

// @TODO dry with placements

function Edit({ row, onClose }: RenderEditCellProps<GridRow, GridSummaryRow>): ReactNode {
  const controller = usePlanController();
  const options = row.config.format?.default || [];
  const value = row.format || [];

  function onSelect(v: Format): void {
    controller.actionRequest({
      method: 'POST',
      url: `plan/rows/${row.id}/format/`,
      body: v,
    });
  }

  function onDeselect(v: Format): void {
    controller.actionRequest({
      method: 'DELETE',
      url: `plan/rows/${row.id}/format/${value.findIndex((c) => getOptionValue(c) === getOptionValue(v))}/`,
      body: v,
    });
  }

  function onAdd(): void {
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Add format"
        initialValues={{ title: '' }}
        submitHandler={(v) => controller.formRequest({ method: 'POST', url: `plan/rows/${row.id}/format/`, body: v })}
      >
        <FormatForm />
      </DialogForm>
    ));
  }

  function onUpdate(index: number, v: Format): void {
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Update format"
        submitHandler={(v) =>
          controller.formRequest({ method: 'PUT', url: `plan/rows/${row.id}/format/${index}/`, body: v })
        }
        initialValues={v}
        onRemove={() => controller.actionRequest({ method: 'DELETE', url: `plan/rows/${row.id}/format/${index}/` })}
      >
        <FormatForm />
      </DialogForm>
    ));
  }

  return (
    <EditableChoiceEditor
      options={options}
      onClose={onClose}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onAdd={onAdd}
      onUpdate={onUpdate}
      formatOptionLabel={(v: Format) => (
        <Box display="flex" alignItems="center" width="full">
          <Box flexGrow={1}>{v.size}</Box>
          <Button
            variant="outline-secondary"
            size="sm"
            className="p-1 border-0 ml-auto"
            testId="view-option"
            onClick={(e) => {
              e.stopPropagation();
              openModal((props) => <ViewDialog {...props} initialValues={v} />, { testId: 'view-option-dialog' });
            }}
          >
            <Icon icon={ICONS[v.material_type]} />
          </Button>
        </Box>
      )}
    />
  );
}

export const FormatColumn = createColumn(
  {
    key: 'format',
    name: 'Format',
    width: 116,
  },
  {
    renderView: (props) => (props.row.kind === 'MEDIA' ? renderValues(props.row.format) : null),
    renderEdit: (props) => <Edit {...props} />,
    displayCellContent: true,
  },
);
