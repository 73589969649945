import { Entity } from 'types';
import { Badge } from 'components';
import { Icon } from 'components/Icon';
import { formatDate, formatDateTime } from 'utilities';
import { getIn } from 'final-form';
import { TableColumn } from '@ff-it/ui';

export function state<T extends Entity & { state: string }>(props?: Partial<TableColumn<T>>): TableColumn<T> {
  return {
    content: 'State',
    width: 90,
    cellProps: {
      flush: true,
      textAlign: 'center',
    },
    key: 'state',
    render: ({ state }) => <Badge state={state} />,
    ...props,
  };
}

export function bool<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string>,
  props?: Partial<TableColumn<T>>,
): TableColumn<T> {
  return {
    content: Header,
    key,
    cellProps: {
      textAlign: 'center',
    },
    render: (e) =>
      e[key] === null ? null : (
        <Icon className={e[key] ? 'text-success' : 'text-danger'} icon={e[key] ? 'check' : 'xmark'} />
      ),
    width: 120,
    ...props,
  };
}

export function date<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string | null> | string,
  { cellProps, ...props }: Partial<TableColumn<T>> = {},
): TableColumn<T> {
  return {
    content: Header,
    key,
    cellProps: {
      textAlign: 'left',
      ...cellProps,
    },
    width: '115px',
    render: (e) => {
      const v = getIn(e, key);

      return e && v ? (formatDate(v) as any) : null;
    },
    ...props,
  };
}

export function dateFrom(props?: Partial<TableColumn<any>>): TableColumn<any> {
  return date('Date from', 'date_from' as any, {
    width: '115px',
    ...props,
  });
}

export function dateTo(props?: Partial<TableColumn<any>>): TableColumn<any> {
  return date('Date to', 'date_to' as any, {
    width: '115px',
    ...props,
  });
}

export function timestamp<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string>,
  props?: Partial<TableColumn<T>>,
): TableColumn<T> {
  return {
    content: Header,
    key,
    cellProps: {
      textAlign: 'left',
    },
    width: '135px',
    render: (e) => (e[key] ? formatDateTime(e[key] as any) : null),
    ...props,
  };
}
