import { DecimalField } from '@ff-it/form';
import { Table, Tbody, Td, Thead, Tr } from '@ff-it/ui';
import { FactorKind } from 'core/types';
import { useFieldValue } from 'hooks';
import { months } from 'options';
import { ReactElement } from 'react';
import { required } from 'utilities';

const monthOptions = months.map((label, idx) => ({ value: idx + 1, label }));

function MonthTable(): ReactElement {
  return (
    <Table marginBottom="md" size="sm" textSize="sm" width="xs">
      <Thead>
        <Tr>
          <Td>Month</Td>
          <Td>Rate, %</Td>
        </Tr>
      </Thead>
      <Tbody>
        {monthOptions.map(({ value, label }) => (
          <Tr key={value}>
            <Td>{label}</Td>
            <Td>
              <DecimalField precision={2} name={`rates[_${value}]`} size="sm" className="mb-0" />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

const weekStart = Array.from(Array(26).keys()).map((_, idx) => ({ value: idx + 1, label: `Week ${idx + 1}` }));
const weekEnd = Array.from(Array(26).keys()).map((_, idx) => ({ value: idx + 27, label: `Week ${idx + 27}` }));

function WeekTable(): ReactElement {
  return (
    <div className="row">
      <div className="col-md-6">
        <Table marginBottom="md" size="sm" textSize="sm">
          <Thead>
            <Tr>
              <Td>Week</Td>
              <Td>Rate, %</Td>
            </Tr>
          </Thead>
          <Tbody>
            {weekStart.map(({ value, label }) => (
              <Tr key={value}>
                <Td>{label}</Td>
                <Td>
                  <DecimalField precision={2} name={`rates[_${value}]`} size="sm" className="mb-0" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <div className="col-md-6">
        <Table marginBottom="md" size="sm" textSize="sm">
          <Thead>
            <Tr>
              <Td>Week</Td>
              <Td>Rate, %</Td>
            </Tr>
          </Thead>
          <Tbody>
            {weekEnd.map(({ value, label }) => (
              <Tr key={value}>
                <Td>{label}</Td>
                <Td>
                  <DecimalField precision={2} name={`rates[_${value}]`} size="sm" className="mb-0" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}

export function FactorKindRateFields(): ReactElement {
  const kind = useFieldValue<FactorKind>('kind');

  switch (kind) {
    case 'CONSTANT':
      return (
        <div className="form-row">
          <DecimalField precision={2} name="rate" label="Rate" required validate={required} className="col-6" />
        </div>
      );
    case 'MONTHLY':
      return <MonthTable />;
    case 'WEEKLY':
      return <WeekTable />;

    default:
      throw new Error(`Unknown kind ${kind}`);
  }
}
