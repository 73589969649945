import cx from 'clsx';
import { createColumn } from '../createColumn';
import { clientColumn, providerColumn, right } from '../style.css';
import { RenderCellProps, RenderEditCellProps } from 'components/DataGrid';
import type { GridRow } from '../../types';
import { CellDropdown } from 'components/DataGrid/editors/CellDropdown';
import invariant from 'tiny-invariant';
import { PriceEditor } from './PriceEditor';
import { ReactNode } from 'react';
import { Box } from '@ff-it/ui';
import { PlanRow } from 'modules/campaign/row';
import { sepFormat } from 'utilities';
import { helpContainer } from './style.css';
import { useUpdateHandler } from './useUpdateHandler';
import { Price } from 'modules/campaign/row/naive';
import { emptyCellClassname } from 'components/DataGrid/style/cell.css';
import eq from 'fast-deep-equal';
import { DotBadge } from '../../CellBadge';
import { RowFactorsDialog } from '../../../Factors';

const prefix = {
  client_price: 'client',
  provider_price: 'provider',
} as const;

const getUnitPrice = (row: PlanRow, kind: 'net' | 'gross', key: 'client_price' | 'provider_price'): string | null => {
  return row.sums.planned[`${prefix[key]}_${kind}_unit_price`];
};

function View({ row, column: { key } }: RenderCellProps<GridRow, any>) {
  invariant(key === 'client_price' || key === 'provider_price');
  const currentPrice = row[key];
  const defaultPrice = row.config[key]?.default;
  const { pricing_model, base_rate, discounts } = currentPrice;

  const netUnitPrice = getUnitPrice(row, 'net', key);
  let help: ReactNode = null;
  switch (pricing_model) {
    case 'RATE':
      help = base_rate ? sepFormat(base_rate) : '—';
      break;
    case 'FIXED':
      // fixed implies net == fixed
      break;
    case 'DISCOUNT':
      help = discounts ? discounts.map((v) => `${v}%`).join(', ') : '—';
  }
  return (
    <>
      {defaultPrice && !eq(defaultPrice, currentPrice) && <DotBadge />}
      <Box data-testid="net-unit-price" fontWeight="semibold" data-price-modal={pricing_model}>
        {netUnitPrice}
      </Box>
      {help && (
        <span data-testid="price-model-value" className={helpContainer}>
          {help}
        </span>
      )}
    </>
  );
}

const factorsKeys = {
  client_price: 'client_factors',
  provider_price: 'provider_factors',
} as const;

function Edit({ row, onClose, column: { key } }: RenderEditCellProps<GridRow, any>) {
  invariant(key === 'client_price' || key === 'provider_price');
  const storedPrice = row[key];
  const defaultPrice = row.config[key]?.default as Price | undefined;
  const netUnitPrice = getUnitPrice(row, 'net', key);
  const grossUnitPrice = getUnitPrice(row, 'gross', key);
  const factorsKey = factorsKeys[key];

  const update = useUpdateHandler(storedPrice, `plan/rows/${row.id}/${key}/`, onClose);

  return (
    <CellDropdown
      // remount on resize
      key={storedPrice.pricing_model}
      onRequestClose={() => {
        update({}, 'commitAndClose');
      }}
    >
      <PriceEditor
        factors={row[factorsKey]}
        price={storedPrice}
        defaultPrice={defaultPrice}
        update={update}
        factorEditor={<RowFactorsDialog row={row} direction={factorsKey} />}
        netUnitPrice={netUnitPrice}
        grossUnitPrice={grossUnitPrice}
      />
    </CellDropdown>
  );
}

const isEmpty = (price: Price): boolean => {
  switch (price.pricing_model) {
    case 'DISCOUNT':
      return price.discounts == null;
    case 'FIXED':
      return price.fixed_rate == null;
    case 'RATE':
      return price.base_rate == null;
  }
};

export const ClientPriceColumn = createColumn(
  {
    key: 'client_price',
    name: 'Client price',
    width: 92,
  },
  {
    renderView: (props) => (props.row.kind !== 'SMART' ? <View {...props} /> : null),
    renderEdit: (props) => (props.row.kind !== 'SMART' ? <Edit {...props} /> : null),
    displayCellContent: true,
    className: (row) =>
      row.kind === 'SMART' ? undefined : cx(clientColumn, right, isEmpty(row.client_price) && emptyCellClassname),
  },
);

export const ProviderPriceColumn = createColumn(
  {
    key: 'provider_price',
    name: 'Provider price',
    className: cx(providerColumn, right),
    width: 92,
  },
  {
    renderView: (props) => (props.row.kind !== 'SMART' ? <View {...props} /> : null),
    renderEdit: (props) => (props.row.kind !== 'SMART' ? <Edit {...props} /> : null),
    displayCellContent: true,
    className: (row) =>
      row.kind === 'SMART' ? undefined : cx(clientColumn, right, isEmpty(row.provider_price) && emptyCellClassname),
  },
);
