import { createOptions } from 'options';

export const applicationStates = ['EMPTY', 'NONE', 'PARTIAL', 'FULL', 'OVERSPENT'] as const;
export type ApplicationState = (typeof applicationStates)[number];

export const applicationStateOptions = createOptions(applicationStates);

export const factorKinds = ['CONSTANT', 'WEEKLY', 'MONTHLY'] as const;
export type FactorKind = (typeof factorKinds)[number];

export const factorKindOptions = createOptions(factorKinds);

export const pricingModels = ['DISCOUNT', 'FIXED', 'RATE'] as const;
export type PricingModel = (typeof pricingModels)[number];

export const pricingModelLabels = {
  DISCOUNT: 'Discount',
  FIXED: 'Fixed net',
  RATE: 'Custom gross',
} as const;
export const pricingModelOptions = createOptions(pricingModels, pricingModelLabels);
