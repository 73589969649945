import type { Price } from 'modules/campaign/row/naive';
import { usePlanController } from '../../../usePlanController';
import { useCallback, useEffect, useRef } from 'react';
import equal from 'fast-deep-equal';

export type UpdateHandler = (payload: Partial<Price>, action?: 'commit' | 'commitAndClose') => void;

export function useUpdateHandler(
  currentPrice: Price,
  url: string,
  close: (commitChanges?: boolean, shouldFocusCell?: boolean) => void,
): UpdateHandler {
  const timeoutRef = useRef<number | null>();

  const controller = usePlanController();
  const payloadRef = useRef<Partial<Price> | null>(null);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        payloadRef.current = null;
      }
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: controller const
  const executeUpdate = useCallback(async () => {
    const body = payloadRef.current;
    timeoutRef.current = null;

    if (body !== null) {
      payloadRef.current = null;
      if (equal(currentPrice, body)) {
        return;
      }
      await controller.actionRequest({ method: 'PUT', url, body });
    }
  }, [url, currentPrice]);

  // Cancel any pending update
  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      payloadRef.current = null;
    }
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: cancel const
  const scheduleUpdate = useCallback(
    (payload: Partial<Price> | null, action?: 'commit' | 'commitAndClose') => {
      const currentPaylaod = payloadRef.current;
      // Cancel any existing pending update
      cancel();
      // Set pending state and schedule new update
      payloadRef.current = currentPaylaod ? { ...currentPaylaod, ...payload } : { ...currentPrice, ...payload };

      if (action === 'commit') {
        executeUpdate();
      } else if (action === 'commitAndClose') {
        executeUpdate();
        close(true, true);
      } else {
        timeoutRef.current = setTimeout(executeUpdate, 500) as unknown as number;
      }
    },
    [close, currentPrice, executeUpdate],
  );

  return scheduleUpdate;
}
