import { fmt, sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { providerColumn, right } from './style.css';
import clsx from 'clsx';

export const ProviderTotalColumn = createColumn(
  {
    key: 'provider_total',
    name: 'Agency total',
    width: 96,
    className: clsx(providerColumn, right),
  },
  {
    renderView: (props) => {
      if (props.row.kind === 'SMART') {
        if (props.row.direction === 'CLIENT') {
          return null;
        }
      }
      const {
        planned: { provider_net_total: planned_expense },
        target: { provider_net_total: target_expense },
      } = props.row.sums;
      return (
        <>
          <div data-testid="planned" className={target_expense === planned_expense ? 'font-weight-bold' : undefined}>
            {sepFormat(planned_expense)}
          </div>
          {target_expense !== planned_expense && (
            <div data-testid="target" className="font-weight-bold">
              {sepFormat(target_expense)}
            </div>
          )}
        </>
      );
    },
  },
  { renderView: (props) => fmt(props.row.providerTotal) },
  { renderSummaryCell: (props) => fmt(props.row.providerTotal) },
);
