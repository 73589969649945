import { ComponentType, ReactElement, ReactNode, useCallback } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { useIsDisabled, useForm, IsDisabledContext } from '@ff-it/form';
import { Fieldset } from './Fieldset';
import { AddButton, RemoveButton } from '../ui/';
import { RequireExactlyOne } from 'type-fest';
import { FormInlineError, FormInlineMemberError } from './FormInlineError';
import type { IconProp } from 'components/Icon';
import { ButtonVariant } from '@ff-it/ui';

export interface FormInlineProps<T> {
  name: string;
  title?: ReactNode;
  initialValues: T;
  children: (name: string, index: number) => ReactElement;
  Row: ComponentType<Omit<FormInlineRowProps, 'children'>>;
  flush?: boolean;
  addLabel?: ReactNode;
  min?: number;
  className?: string;
  help?: string;
  testId?: string;
  disabled?: boolean;
}

interface FormInlineAddProps {
  name: string;
  variant?: ButtonVariant;
  icon?: IconProp;
  addLabel?: ReactNode;
  initialValues: any;
  className?: string;
  testId?: string;
}
/** @deprecated */
export function FormInlineAdd({
  name,
  initialValues,
  addLabel = 'Add',
  testId = 'add-inline',
  ...btnProps
}: FormInlineAddProps): ReactElement {
  const {
    mutators: { push },
  } = useForm();

  const isDisabled = useIsDisabled();
  // biome-ignore lint/correctness/useExhaustiveDependencies: form mutators are stable
  const addClickHandler = useCallback(() => {
    push(
      name,
      typeof initialValues === 'function'
        ? initialValues()
        : {
            ...initialValues,
            __NEW__: Date.now(),
          },
    );
  }, [name, initialValues]);

  return (
    <AddButton testId={testId} onClick={addClickHandler} disabled={isDisabled} {...btnProps}>
      {addLabel}
    </AddButton>
  );
}

interface FormInlineRowProps {
  name: string;
  children?: ReactNode;
  remove?: () => void;
  className?: string;
}

/** @deprecated */
export function FormInlineRow({ children, remove, className = 'd-flex flex-row' }: FormInlineRowProps): ReactElement {
  return (
    <div className={className}>
      <div className="flex-grow-1">{children}</div>
      <div className="form-group ml-2 d-flex align-items-start">
        <RemoveButton data-test-id="remove-inline" onClick={remove} disabled={!remove} />
      </div>
    </div>
  );
}

/** @deprecated */
export function FormInline<T = any>({
  name,
  title,
  initialValues,
  children,
  Row,
  flush,
  addLabel,
  min,
  className,
  help,
  testId,
  disabled: localDisabled,
  header,
}: RequireExactlyOne<FormInlineProps<T>, 'children' | 'Row'> & {
  header?: ReactNode;
}): ReactElement {
  const { fields } = useFieldArray(name, {
    subscription: {},
    allowNull: true,
  });
  const disabled = useIsDisabled(localDisabled);
  return (
    <IsDisabledContext.Provider value={disabled}>
      <Fieldset title={title} flush={flush} className={className} testId={testId}>
        <FormInlineError name={name} />
        {header}
        {fields.map((name, index) => {
          const remove = !(disabled || Boolean(min && min > index)) ? () => fields.remove(index) : undefined;
          return Row ? (
            <Row name={name} key={index} remove={remove} />
          ) : (
            <FormInlineRow name={name} key={index} remove={remove}>
              <FormInlineMemberError name={name} />
              {children ? children(name, index) : null}
            </FormInlineRow>
          );
        })}
        <div className="d-flex align-items-center">
          <FormInlineAdd name={name} initialValues={initialValues} addLabel={addLabel} />
          {help && <small className="form-text text-muted ml-2 mt-0">{help}</small>}
        </div>
      </Fieldset>
    </IsDisabledContext.Provider>
  );
}
