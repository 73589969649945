import { forwardRef, MouseEvent, ReactElement, ReactNode, Ref } from 'react';
import cx from 'clsx';
import type { BoundState as BoundStateType } from 'modules/campaign/common/types';
import './Badge.scss';
import { Icon } from '../../Icon';

interface BadgeProps {
  className?: string;
  state: BoundStateType;
  is_overspent: boolean;
  variant: 'income' | 'expense';
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
}

const titles: Record<BoundStateType, string> = {
  EMPTY: 'Empty',
  NONE: 'Nothing linked',
  PARTIAL: 'Partially linked',
  FULL: 'Everything linked',
  // OVERSPENT: 'Linked more than value',
};

export const Badge = forwardRef(function Badge(
  { state, variant, onClick, children, className, is_overspent = false }: BadgeProps,
  ref: Ref<HTMLSpanElement>,
): ReactElement {
  return (
    <span
      className={cx('bound-state', className, state, variant, is_overspent && 'overspent')}
      onClick={onClick}
      ref={ref}
      title={is_overspent ? 'Linked more than value' : titles[state]}
      tabIndex={-1}
    >
      {is_overspent && <span className="overspent" />}
      {state === 'FULL' && is_overspent ? <Icon icon="exclamation" /> : null}
      {children}
    </span>
  );
});
