import type { DepartmentFactor } from 'modules/supplier/factors';
import type { Unit } from 'types';

// factor.py:factor_is_applicable, except for department part

export function factorIsApplicable(rowUnit: Unit | null, factor: DepartmentFactor): boolean {
  switch (factor.kind) {
    case 'MONTHLY': {
      if (rowUnit === 'DAY' || rowUnit === 'WEEK' || rowUnit === 'MONTH') {
        return true;
      }
      return false;
    }
    case 'WEEKLY': {
      if (rowUnit === 'DAY' || rowUnit === 'WEEK' || rowUnit === 'MONTH') {
        return true;
      }
      return false;
    }
  }

  return true;
}
