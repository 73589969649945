import cx from 'clsx';
import { createColumn } from './createColumn';
import { clientColumn, providerColumn, right } from './style.css';
export const ClientEffectiveDiscountColumn = createColumn(
  {
    key: 'client_effective_discount',
    name: 'Ef. discount',
    className: cx(clientColumn, right),
    width: 80,
  },
  {
    renderView: ({ row }) =>
      row.kind !== 'SMART' && row.sums.planned.client_effective_discount !== null
        ? `${row.sums.planned.client_effective_discount}%`
        : null,
  },
);

export const ProviderEffectiveDiscountColumn = createColumn(
  {
    key: 'provider_effective_discount',
    name: 'Ef. discount',
    className: cx(providerColumn, right),
    width: 92,
  },
  {
    renderView: ({ row }) =>
      row.kind !== 'SMART' && row.sums.planned.provider_effective_discount != null
        ? `${row.sums.planned.provider_effective_discount}%`
        : null,
  },
);
