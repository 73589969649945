import { DecimalEditor, RenderCellProps, RenderEditCellProps } from 'components/DataGrid';
import { resetBtn, wrapContainer } from './style.css';
import type { ReactNode } from 'react';
import { sepFormat } from 'utilities';
import { PlanRow } from 'modules/campaign/row';
import { DotBadge } from '../CellBadge';
import type { GridRow, GridSummaryRow } from '../types';
import { Button } from '@ff-it/ui';
import { Icon } from 'components/Icon';

export function renderView({
  column,
  field = column.key,
  row,
}: RenderCellProps<GridRow, GridSummaryRow> & { field?: string }): ReactNode {
  const value = row[field as keyof PlanRow] as any;
  const price = row.config[field as keyof PlanRow['config']]?.default;

  if (value) {
    return (
      <>
        {price && price !== value && <DotBadge />}
        {value ? (
          <span className={wrapContainer} title={value}>
            {sepFormat(value)}
          </span>
        ) : null}
      </>
    );
  }
  return null;
}

export function renderEdit(
  props: RenderEditCellProps<GridRow, GridSummaryRow> & {
    precision?: number;
    normalize?: boolean;
    field?: string;
  },
): ReactNode {
  const { row, precision, normalize, field = props.column.key } = props;
  const value = row[props.column.key as keyof PlanRow] as any;
  const price = row.config[props.column.key as keyof PlanRow['config']]?.default;
  const reset = price && price !== value;
  return (
    <>
      {reset && (
        <Button
          variant={null}
          className={resetBtn}
          testId="reset-default"
          onMouseDown={(e: any) => {
            e.preventDefault();
            props.onRowChange({ ...props.row, [field]: price }, true);
          }}
        >
          <Icon icon="rotate-left" />
        </Button>
      )}
      <DecimalEditor precision={precision} normalize={normalize} {...props} />
    </>
  );
}
