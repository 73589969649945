import type { ReactElement } from 'react';
import { TitleContainer } from 'components/TitleContainer';
import type { EmbeddedCompany } from 'types';
import { Box } from '@ff-it/ui';
import { Icon } from 'components/Icon';

type CompanyTitleProps = EmbeddedCompany & {
  className?: string;
};

export function CompanyTitle({ id, title, internal_name, kind, className }: CompanyTitleProps): ReactElement {
  return (
    <TitleContainer
      testId={`company-${id}`}
      title={internal_name}
      subtitle={
        <>
          {kind === 'branch' && <Icon icon="diagram-subtask" className="mr-1" />} <Box component="span">{title}</Box>
        </>
      }
      className={className}
    />
  );
}
