import { fmt, sepFormat } from 'utilities';
import { createColumn } from './createColumn';
import { expenseForeground, incomeForeground, right } from './style.css';
import { Box } from '@ff-it/ui';

export const GrossTotalColumn = createColumn(
  {
    key: 'gross_total',
    name: 'Gross total',
    className: right,
    width: 96,
  },
  {
    renderView: (props) => {
      if (props.row.kind === 'SMART') {
        return null;
      }
      const { client_gross_total, provider_gross_total } = props.row.sums.planned;
      const split = client_gross_total !== provider_gross_total;
      return (
        <Box lineHeight="xs">
          <Box
            data-testid="client"
            className={split ? incomeForeground : undefined}
            fontWeight={split ? 'bold' : undefined}
          >
            {sepFormat(client_gross_total)}
          </Box>
          {split && (
            <Box data-testid="provider" className={expenseForeground}>
              {sepFormat(provider_gross_total)}
            </Box>
          )}
        </Box>
      );
    },
  },
  { renderView: (props) => fmt(props.row.clientGrossTotal) },
  { renderSummaryCell: (props) => fmt(props.row.clientGrossTotal) },
);
