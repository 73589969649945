import { pricingModelOptions } from 'core/types';
import type { Price } from 'modules/campaign/row/naive';
import { ChangeEvent, KeyboardEvent, ReactElement, ReactNode, useRef, useState } from 'react';
import { editorContainer, gridContainer, headerCell, tabButton, tabContainer, resetBtn } from './style.css';
import cx from 'clsx';
import { DiscountCell, FactorsCell, PriceCell } from './cells';
import type { UpdateHandler } from './useUpdateHandler';
import { EditCell } from './cells';
import { formatDecimalValue, parseDecimalInput } from 'utilities';
import invariant from 'tiny-invariant';
import equal from 'fast-deep-equal';
import { Icon } from 'components/Icon';
import { HelpPopover } from './HelpPopover';

type PriceEditorProps = {
  price: Price;
  factors: number[];
  update: UpdateHandler;
  factorEditor: ReactNode;
  netUnitPrice: string | null;
  grossUnitPrice: string | null;
};

function Discount({ price, factors, netUnitPrice, grossUnitPrice, update, factorEditor }: PriceEditorProps) {
  const { discounts } = price;

  const [state, setState] = useState(() => {
    if (discounts === null) {
      return ['', '', ''];
    }
    const result = [...discounts];
    while (result.length < 3) {
      result.push('');
    }
    return result;
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const unformattedValue = e.target.value;
    const parsedValue = parseDecimalInput(unformattedValue, 6, 9);

    // update input
    if (parsedValue !== state[idx]) {
      const nextState = state.with(idx, parsedValue);
      setState(nextState);

      const discounts = nextState.map((v) => formatDecimalValue(v, -1)).filter((v): v is string => v !== null);
      update({ discounts: discounts.length === 0 ? null : discounts });
    }
  };

  return (
    <div className={gridContainer}>
      <div className={headerCell}>Factors</div>
      <div className={headerCell}>Gross price</div>
      <div className={headerCell}>Discounts</div>
      <div className={headerCell}>Net price</div>
      <FactorsCell factors={factors} factorEditor={factorEditor} />
      <PriceCell value={grossUnitPrice} />
      <DiscountCell value={state[0]} onChange={(e) => onChange(e, 0)} />
      <PriceCell value={netUnitPrice} fontWeight="semibold" />
      {(state[0] !== '' || state[1] !== '') && <DiscountCell value={state[1]} onChange={(e) => onChange(e, 1)} extra />}
      {(state[1] !== '' || state[2] !== '') && <DiscountCell value={state[2]} onChange={(e) => onChange(e, 2)} extra />}
    </div>
  );
}

function Grid(props: PriceEditorProps): ReactElement {
  const {
    price: { pricing_model },
    factorEditor,
    netUnitPrice,
    grossUnitPrice,
    factors,
  } = props;

  switch (pricing_model) {
    case 'FIXED':
      return (
        <div className={gridContainer}>
          <div className={headerCell}>Factors</div>
          <div className={headerCell}>Gross price</div>
          <div className={headerCell}>Fixed net</div>
          <div className={headerCell}>Net price</div>
          <FactorsCell factors={factors} factorEditor={factorEditor} />
          <PriceCell value={grossUnitPrice} />
          <EditCell field="fixed_rate" price={props.price} update={props.update} />
          <PriceCell value={netUnitPrice} fontWeight="semibold" />
        </div>
      );
    case 'RATE':
      return (
        <div className={gridContainer}>
          <div className={headerCell}>Cust. gross</div>
          <div className={headerCell}>Factors</div>
          <div className={headerCell}>Gross price</div>
          <div className={headerCell}>Net price</div>
          <EditCell field="base_rate" price={props.price} update={props.update} />
          <FactorsCell factors={factors} factorEditor={factorEditor} />
          <PriceCell value={grossUnitPrice} />
          <PriceCell value={netUnitPrice} fontWeight="semibold" />
        </div>
      );
    case 'DISCOUNT':
      return <Discount {...props} />;
  }
  throw new Error(`Unknown pricing model: ${pricing_model}`);
}

export function PriceEditor(
  props: PriceEditorProps & {
    defaultPrice?: Price;
  },
): ReactElement {
  const { update, price, defaultPrice } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    // On enter or moving out we flush and close
    if (e.key === 'Enter') {
      e.preventDefault();
      update({}, 'commitAndClose');
      return;
    }
    if (e.key !== 'Tab') return;
    invariant(containerRef.current);
    const focusableElements = containerRef.current.querySelectorAll('input, [tabindex]:not([tabindex="-1"])');
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    // Check if focus is about to leave the container
    const isLeavingForward = e.target === lastElement && !e.shiftKey;
    const isLeavingBackward = e.target === firstElement && e.shiftKey;

    if (isLeavingForward || isLeavingBackward) {
      e.preventDefault();
      update({}, 'commitAndClose');
    }
  };

  return (
    <div className={editorContainer} data-testid="price-editor" ref={containerRef} onKeyDown={onKeyDown}>
      <Grid {...props} />
      <div className={tabContainer}>
        {pricingModelOptions.map(({ label, value }) => (
          <button
            type="button"
            className={cx(tabButton, {
              active: price.pricing_model === value,
            })}
            tabIndex={-1}
            key={value}
            disabled={price.pricing_model === value}
            onMouseDown={(e) => {
              // we don't want to grab focus here
              e.preventDefault();
              // send whole price so we can avoid mergining with pending state of current tab
              update({ ...props.price, pricing_model: value }, 'commit');
            }}
          >
            {label}
          </button>
        ))}
        {defaultPrice && !equal(defaultPrice, price) && (
          <button
            data-testid="reset-default"
            type="button"
            tabIndex={-1}
            className={resetBtn}
            title="Reset"
            onClick={() => {
              update(defaultPrice, 'commit');
            }}
          >
            <Icon icon="rotate-left" />
          </button>
        )}
        <HelpPopover />
      </div>
    </div>
  );
}
