import { ReactElement, createElement } from 'react';
import { ProductKind, type ActivityType } from 'types';
import { applicationOptions, getUnitOptions, productKindOptions } from 'options';
import { CheckField, DecimalField, SelectField, TextField, useField } from '@ff-it/form';
import { Config, ConfigToggle } from './Config';
import { ComponentType } from 'react';
import { ActivityField, AllActivityTypesField } from 'components';
import { required } from 'utilities';
import { CategoryField } from 'modules/supplier/category/MediaCategoryField';
import { directionOptions } from 'modules/campaign/row/smart';

type FieldsProps = {
  type: ActivityType;
};

function MediaFields({ type }: FieldsProps): ReactElement {
  return (
    <>
      <div className="row">
        <SelectField name="unit" label="Unit" className="col-md-2" options={getUnitOptions('MEDIA', type)} simple />
        <DecimalField name="gross_price" label="Gross price" help="Price of unit" className="col-md-2" precision={16} />
      </div>

      <div className="row">
        <ActivityField
          name="activities"
          label="Activities"
          className="col-md-4"
          isMulti
          kind="MEDIA"
          help="Leave empty for any"
        />
        <CheckField
          name="expect_performance"
          label="Expect performance"
          className="col-md-4"
          variant="switch"
          fieldLabel="Has delivery"
          help="Actual amounts and price logged after delivery"
        />
        <ConfigToggle type={type} className="col-md-3" />
      </div>
      <Config type={type} />
    </>
  );
}

function ServiceFields({ type }: FieldsProps): ReactElement {
  return (
    <>
      <div className="form-row">
        <SelectField name="unit" label="Unit" className="col-md-2" options={getUnitOptions('SERVICE', type)} simple />
      </div>
      <div className="form-row">
        <ActivityField
          name="activities"
          label="Activities"
          className="col-md-4"
          isMulti
          kind="SERVICE"
          help="Leave empty for any"
        />
      </div>
    </>
  );
}

function SmartFields({ type: _ }: FieldsProps): ReactElement {
  return (
    <>
      <div className="form-row">
        <SelectField
          name="direction"
          label="Result direction"
          className="col-md-2"
          options={directionOptions}
          simple
          required
        />
        <SelectField
          name="application"
          label="Application"
          className="col-md-2"
          options={applicationOptions}
          simple
          required
        />
      </div>
      <div className="form-row">
        <ActivityField
          name="activities"
          label="Activities"
          className="col-md-4"
          isMulti
          kind="SERVICE"
          help="Leave empty for any"
        />
      </div>
    </>
  );
}

interface FormProps {
  create: boolean;
}

const FIELDS: Record<ProductKind, ComponentType<FieldsProps>> = {
  MEDIA: MediaFields,
  SERVICE: ServiceFields,
  SMART: SmartFields,
};

export function Form({ create }: FormProps): ReactElement {
  const {
    input: { value: type },
  } = useField<ActivityType | null>('type', { subscription: { value: true }, allowNull: true });

  const {
    input: { value: kind },
  } = useField<ProductKind | null>('kind', { subscription: { value: true }, allowNull: true });

  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <SelectField
          name="kind"
          label="Kind"
          options={productKindOptions}
          simple
          className="col-md-2"
          disabled={!create}
          required
          validate={required}
        />
        <AllActivityTypesField
          name="type"
          label="Type"
          className="col-md-1"
          disabled={!create}
          required
          validate={required}
        />
        <TextField name="name" label="Name" className="col-md-4" help="Blank for generic" />
      </div>
      <div className="row">
        <CategoryField name="category" label="Category" className="col-md-3" help="Leave empty to inherit" />
        <CheckField
          variant="switch"
          name="disabled"
          label="Disable product"
          className="col-md-3"
          help="Mark disabled and hide in product picker"
        />
      </div>
      {kind && type ? createElement(FIELDS[kind], { type }) : null}
    </div>
  );
}
