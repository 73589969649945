import {
  Alert,
  Button,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  LoadingContainer,
  openModal,
} from '@ff-it/ui';
import { usePlanFactors } from './useFactors';
import { GridRow } from '../Grid/types';
import { DepartmentFactor } from 'modules/supplier/factors';
import { content, optionsContainer } from './RowFactorsDialog.css';
import { useState } from 'react';
import { Icon } from 'components/Icon';
import { PlanController, usePlanController } from '../usePlanController';
import useSWR from 'swr';
import invariant from 'tiny-invariant';
import { PlanFactor } from 'modules/campaign/block/types';
import { FactorOption } from './FactorOption';
import { DialogForm } from 'components';
import { SelectField, TextField } from '@ff-it/form';
import { required } from 'utilities';
import { factorKindOptions } from 'core/types';
import { FactorKindRateFields } from 'modules/supplier/factors/FactorKindRateFields';
import { factorIsApplicable } from './factorIsApplicable';

type FactorEditorProps = {
  row: GridRow;
  direction: 'client_factors' | 'provider_factors';
};

type FactorRow<T extends boolean = boolean> = {
  factor: T extends true ? PlanFactor : DepartmentFactor;
  isPlan: T;
  clientSelected: boolean;
  providerSelected: boolean;
};

export function useRowFactors(
  controller: PlanController,
  row: GridRow,
): {
  factors: FactorRow[];
  handleFactorToggle: (
    factorId: number,
    direction: 'client_factors' | 'provider_factors',
    isChecked: boolean,
    departmentFactor?: DepartmentFactor,
  ) => Promise<void>;
  isLoading: boolean;
} {
  invariant(row.supplier);
  const {
    supplier: { product },
    client_factors,
    provider_factors,
  } = row;
  const [isPending, setIsPending] = useState<boolean>(false);

  const { data: allDepartmentFactors, isLoading } = useSWR<DepartmentFactor[], unknown>({
    url: `supplier/departments/${product.department.id}/factors/`,
    method: 'GET',
    queryParams: { type: product.type },
  });

  const allPlanFactors = usePlanFactors();

  const departmentIds = new Set([product.department.id || 0]);
  // product might get assigned different department

  const selectedClientFactors = new Set(client_factors);
  const selectedProviderFactors = new Set(provider_factors);

  // track factors that are linked to department factors
  const localFactors = new Set();
  const planFactors = allPlanFactors.filter((f) => {
    if (f.department_factor) {
      localFactors.add(f.department_factor);
    }
    return departmentIds.has(f.department.id) || selectedClientFactors.has(f.id) || selectedProviderFactors.has(f.id);
  });

  const departmentFactors = allDepartmentFactors ? allDepartmentFactors.filter((f) => !localFactors.has(f.id)) : [];

  const handleFactorToggle = async (
    factorId: number,
    direction: 'client_factors' | 'provider_factors',
    isChecked: boolean,
    departmentFactor?: DepartmentFactor,
  ) => {
    setIsPending(true);
    let newFactorId = factorId;
    try {
      const currentFactors = new Set(row[direction]);

      if (isChecked) {
        if (departmentFactor) {
          const newFactor = await controller.createPlanFactorFromDepartmentFactor(departmentFactor, product.department);
          newFactorId = newFactor.id;
        }
        currentFactors.add(newFactorId);
      } else {
        currentFactors.delete(newFactorId);
      }

      await controller.actionRequest({
        method: 'PUT',
        url: `plan/rows/${row.id}/${direction}/`,
        body: Array.from(currentFactors),
      });
    } finally {
      setIsPending(false);
    }
  };

  // Combine and sort all factors
  const factors: FactorRow[] = [
    ...planFactors.map((factor) => ({
      factor,
      isPlan: true as const,
      clientSelected: selectedClientFactors.has(factor.id),
      providerSelected: selectedProviderFactors.has(factor.id),
    })),
    ...departmentFactors.map((factor) => ({
      factor,
      isPlan: false as const,
      clientSelected: false, // Department factors are not selected by default
      providerSelected: false,
    })),
  ].sort((a, b) => {
    // Then sort by name
    const nameA = a.factor.name.toLowerCase();
    const nameB = b.factor.name.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  return {
    isLoading: isLoading || isPending,
    factors,
    handleFactorToggle,
  };
}

function FactorFields() {
  return (
    <>
      <div className="form-row">
        <TextField name="name" label="Name" required validate={required} className="col-8" />
        <SelectField
          name="kind"
          label="Kind"
          required
          validate={required}
          simple
          options={factorKindOptions}
          className="col-4"
        />
      </div>
      <TextField name="description" label="Description" multiline autosize />
      <FactorKindRateFields />
    </>
  );
}
const initialFactorValues = {
  type: null,
  name: '',
  description: '',
  kind: 'CONSTANT',
  rate: null,
  rates: null,
};

function FactorEditor({ row, direction: _ }: FactorEditorProps) {
  // We should probably do something about direction
  invariant(row.supplier);
  const product = row.supplier.product;

  const controller = usePlanController();
  const { isLoading, factors, handleFactorToggle } = useRowFactors(controller, row);

  const isClientLocked = row.config.client_price.locked;
  const isProviderLocked = row.config.provider_price.locked;

  return (
    <LoadingContainer loading={isLoading}>
      <DialogHeader>Factors for {product.department.name}</DialogHeader>
      <DialogBody>
        <div className={optionsContainer} data-testid="factor-options">
          {factors.length === 0 && <Alert className="mb-0">No factors</Alert>}
          {factors.map((factorRow) => {
            const { factor, isPlan, clientSelected, providerSelected } = factorRow;
            const isNotApplicable = !factorIsApplicable(row.unit, factor);
            return (
              <FactorOption
                key={factor.id}
                factor={factor}
                isPlan={isPlan}
                clientChecked={clientSelected}
                providerChecked={providerSelected}
                onToggle={handleFactorToggle}
                isClientLocked={isClientLocked || isNotApplicable}
                isProviderLocked={isProviderLocked || isNotApplicable}
              >
                {/* {isPlan ? 'P' : 'D'} */}
                {/* if it's plan factor and it's not linked */}
                {isPlan && (factor as PlanFactor).department_factor === null && (
                  <Button
                    size="sm"
                    variant="outline-primary"
                    className="border-0"
                    testId={`edit-factor-${factor.id}`}
                    tabIndex={-1}
                    onClick={async () => {
                      await openModal(
                        (props) => (
                          <DialogForm
                            {...props}
                            dialogHeader={`Update factor for ${product.department.name}`}
                            initialValues={factor}
                            submitHandler={(data) => controller.updateFactor(factor.id, data)}
                            onRemove={() => controller.removeFactor(factor.id)}
                          >
                            <Alert variant="warning" className="p-2">
                              <small>
                                Updating the factor rate or removing a factor may impact row pricing — even for locked
                                rows.
                              </small>
                            </Alert>
                            <FactorFields />
                          </DialogForm>
                        ),
                        { testId: 'update-factor-dialog' },
                      );
                    }}
                  >
                    <Icon icon="pencil" />
                  </Button>
                )}
              </FactorOption>
            );
          })}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          onClick={async () => {
            await openModal(
              (props) => (
                <DialogForm
                  {...props}
                  dialogHeader={`Create factor for ${product.department.name}`}
                  initialValues={{ ...initialFactorValues, department: product.department }}
                  submitHandler={controller.addFactor}
                >
                  <FactorFields />
                </DialogForm>
              ),
              { testId: 'add-factor-dialog' },
            );
          }}
        >
          Add factor <Icon icon="plus" />
        </Button>

        <DialogClose className="ml-auto">Close</DialogClose>
      </DialogFooter>
    </LoadingContainer>
  );
}

export function RowFactorsDialog(props: FactorEditorProps) {
  // We don't want to render unless we open the dialog
  return (
    <DialogContent className={content} testId="row-factors-dialog">
      <FactorEditor {...props} />
    </DialogContent>
  );
}
