import { useRef, useEffect } from 'react';
import useSWR from 'swr/immutable';
import { SelectField, useForm, useOnFieldChange } from '@ff-it/form';
import { SelectInstance } from '@ff-it/ui';
import type { EmbeddedCompany } from 'types';
import { ProductField } from 'modules/supplier/products/ProductField';
import { EmbeddedProduct } from 'modules/supplier/products';
import { companyFieldProps } from 'modules/core/company/components';
import { FieldsProps } from './types';
import { ProductActivityField } from './ProductActivityField';
import { initialState } from './const';

export function ProductFirst({ type, kind, required, department }: FieldsProps): React.ReactElement {
  const form = useForm();
  const providerFieldRef = useRef<SelectInstance<any>>(null);

  const product = useOnFieldChange<EmbeddedProduct | null>(
    'product',
    (value) => {
      if (value) {
        form.change('provider', null);
      } else {
        form.initialize(initialState);
      }
    },
    false, // clearing does not handle blur and we reset with with kind
  );

  const { data: providers } = useSWR<EmbeddedCompany[]>(
    product
      ? {
          url: `supplier/providers/`,
          method: 'GET',
          queryParams: {
            include_branches: true,
            product: product.id,
          },
        }
      : null,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: this is fine
  useEffect(() => {
    if (!product) {
      return;
    }

    if (form.getFieldState('provider')?.value === null) {
      if (providers && providers.length === 1) {
        const guess = providers[0];
        form.change('provider', guess);
        return;
      }
      providerFieldRef.current?.focus();
    }
  }, [providers]);

  const disabled = !product;

  return (
    <>
      <ProductField
        label="Product"
        type={type}
        kind={kind}
        name="product"
        menuPortalTarget={document.body}
        defaultOptions
        required={required}
        key={`${kind}-${department || 0}`}
        autoFocus={!product}
        openMenuOnFocus
        department={department}
        disabled={false}
      />
      <SelectField<EmbeddedCompany>
        label="Provider"
        name="provider"
        menuPortalTarget={document.body}
        options={providers || []}
        {...companyFieldProps}
        disabled={disabled}
        required
        selectRef={providerFieldRef}
        openMenuOnFocus
      />
      <ProductActivityField />
    </>
  );
}
