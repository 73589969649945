import { RenderCellProps } from 'components/DataGrid';
import { createColumn } from '../createColumn';
import { renderEdit } from '../resettableDecimal';
import {
  expenseForeground,
  incomeBackground,
  incomeForeground,
  expenseBackground,
  right,
  wrapContainer,
} from '../style.css';
import type { GridRow, GridSummaryRow } from '../../types';
import type { ReactNode } from 'react';
import { CellBagde, DotBadge } from '../../CellBadge';
import cx from 'clsx';
import { ReactElement } from 'react';
import { Box, Popover } from '@ff-it/ui';
import { sepFormat } from 'utilities';
import { usePlanFactors } from '../../../Factors';
import { applicationContainer, card } from './style.css';
import { FactorCard } from 'modules/supplier/factors';

function ApplicationDetails({
  row: {
    client_factors,
    client_price,
    provider_factors,
    provider_price,
    gross_price,
    sums: {
      planned: { client_gross_unit_price, provider_gross_unit_price },
    },
  },
}: { row: GridRow }): ReactElement {
  const planFactors = usePlanFactors();
  return (
    <Box padding="sm" fontSize="sm" className={applicationContainer}>
      <Box display="flex" flexDirection="row" fontWeight="semibold">
        Gross p.u
        <Box component="span" fontSize="sm" marginLeft="auto">
          {sepFormat(gross_price)}
        </Box>
      </Box>

      <Box className={incomeBackground}>
        <Box display="flex" flexDirection="row" fontWeight="semibold">
          Client gross
          <Box component="span" fontSize="sm" marginLeft="auto" className={incomeForeground}>
            {sepFormat(client_gross_unit_price)}
          </Box>
        </Box>
        {client_factors.map((factorId) => {
          const f = planFactors.find((v) => v.id === factorId)!;
          return <FactorCard key={factorId} factor={f} className={card} />;
        })}
      </Box>
      <Box className={expenseBackground}>
        <Box display="flex" flexDirection="row" fontWeight="semibold">
          Provider gross
          <Box component="span" fontSize="sm" marginLeft="auto" className={expenseForeground}>
            {sepFormat(provider_gross_unit_price)}
          </Box>
        </Box>
        {provider_factors.map((factorId) => {
          const f = planFactors.find((v) => v.id === factorId)!;
          return <FactorCard key={factorId} factor={f} className={card} />;
        })}
      </Box>
    </Box>
  );
}

// Shows applied factors on hover
export function FactorBadge({ row }: { row: GridRow }): ReactElement {
  return (
    <Popover interaction="hover" placement="left" portal content={<ApplicationDetails row={row} />}>
      <CellBagde icon="asterisk" placement="bottom-start" testId="application-details" />
    </Popover>
  );
}

function renderView({ row }: RenderCellProps<GridRow, GridSummaryRow>): ReactNode {
  const {
    gross_price,
    client_factors,
    client_price,
    provider_factors,
    provider_price,
    sums: {
      planned: { client_gross_unit_price, provider_gross_unit_price },
    },
  } = row;
  const defaultPrice = row.config['gross_price']?.default;

  if (gross_price) {
    const split = client_gross_unit_price !== provider_gross_unit_price;

    return (
      <Box lineHeight="xs" paddingLeft="sm">
        {(client_factors.length > 0 || provider_factors.length > 0) && <FactorBadge row={row} />}
        {defaultPrice && defaultPrice !== gross_price && <DotBadge />}
        <Box
          data-testid="client"
          className={cx(split ? incomeForeground : undefined, wrapContainer)}
          fontWeight={split ? 'bold' : undefined}
        >
          {sepFormat(client_gross_unit_price)}
        </Box>
        {split && (
          <Box data-testid="provider" className={cx(expenseForeground, wrapContainer)}>
            {sepFormat(provider_gross_unit_price)}
          </Box>
        )}
      </Box>
    );
  }
  return null;
}

export const GrossPriceColumn = createColumn(
  {
    key: 'gross_price',
    name: 'Gross p.u.',
    width: 96,
    className: right,
  },
  {
    renderView,
    renderEdit: (props) => renderEdit({ ...props, precision: 16, normalize: true }),
  },
);
