import { Action, DialogForm } from 'components';
import { openModal } from '@ff-it/ui';
import { TextField } from '@ff-it/form';
import { toast } from 'react-toastify';
import type { GridRow } from './Grid/types';
import { usePlanController } from './usePlanController';

export function useRowActions(row: GridRow): (Action | '-')[] {
  const controller = usePlanController();

  const locked = !controller.planning || row.state.is_locked;

  const actions: (Action | '-')[] = [
    {
      action: 'comment',
      button: {
        children: 'Comment',
        disabled: row.config.comment.locked, // we can comment even for locked plan or row
        icon: 'comment-dots',
        onClick: () =>
          openModal((props) => (
            <DialogForm
              {...props}
              initialValues={{ comment: row.comment }}
              submitHandler={({ comment }: any) =>
                controller.formRequest({ method: 'PUT', url: `plan/rows/${row.id}/comment/`, body: comment })
              }
            >
              <TextField name="comment" label="Comment" multiline autosize required />
            </DialogForm>
          )),
      },
    },
    {
      action: 'copy',
      button: {
        children: 'Copy',
        disabled: !controller.planning,
        icon: 'copy',
        onClick: async () => {
          await controller.copyRow(row);
          toast.success(`Copied row!`);
        },
      },
    },
    {
      action: 'remove',
      button: {
        children: 'Delete',
        disabled: locked,
        variant: 'danger',
        icon: 'xmark',
        onClick: async () => {
          await controller.removeRow(row.id);
          toast.warn(`Deleted row!`);
        },
      },
    },
  ];

  return actions;
}
