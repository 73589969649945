import type { ReactElement } from 'react';
import { Fieldset, ItemLoader, Period, useEntity } from 'components';
import { EnterpriseLink } from 'components/EnterpriseLink';
import { AgreementTypeTerms, ClientAgreement } from 'modules/client/agreement/types';
import { useParams } from 'react-router';
import { BlockDetails } from '../types';
import { nl2br } from 'utilities';
import { Alert, Box, Table, Tbody, Td, Th, Thead, Tr } from '@ff-it/ui';
import { SupplierTitle } from 'modules/supplier/supplier/components';

export function Agreement(): ReactElement {
  const {
    item: { type },
  } = useEntity<BlockDetails>();
  // campaing_id
  const { id } = useParams();

  const url = `campaign/campaigns/${id}/agreement/${type}/`;
  return (
    <ItemLoader<ClientAgreement & { block_terms: AgreementTypeTerms | null }> url={url}>
      {({ item: agreement }) => {
        const { block_terms } = agreement;
        return (
          <div className="container ml-0">
            <Box>
              <EnterpriseLink
                to={`/contacts/clients/${agreement.client.id}/agreements/${agreement.id}/terms/${type}`}
                data-testid="agreement-link"
              >
                {agreement.number}
              </EnterpriseLink>{' '}
              <Period date_from={agreement.date_from} date_to={agreement.date_to} flat />
            </Box>
            <div className="row">
              {agreement.terms && (
                <div className="pt-3 col-md-6">
                  <Fieldset title="General terms" testId="general-terms" flush>
                    {nl2br(agreement.terms)}
                  </Fieldset>
                </div>
              )}
              {agreement.client_po_number && (
                <div className="pt-3 col-md-6">
                  <Fieldset title="PO number" testId="client-po-number" flush>
                    {agreement.client_po_number}
                  </Fieldset>
                </div>
              )}
            </div>
            {block_terms ? (
              <Fieldset title={type}>
                {block_terms.terms && (
                  <Fieldset title="Type terms" testId="type-terms" flush>
                    {nl2br(block_terms.terms)}
                  </Fieldset>
                )}
                {block_terms.smart_rate_groups.length > 0 && (
                  <Fieldset title="Smart rates" testId="smart-rate-groups" flush>
                    {block_terms.smart_rate_groups.map(({ id, rates }) => (
                      <Table key={id} marginBottom="md" size="sm" textSize="sm" fixed>
                        <colgroup>
                          <col />
                          <col width="90px" />
                          <col width="90px" />
                          <col width="160px" />
                          <col width="260px" />
                          <col width="160px" />
                        </colgroup>
                        <Thead variant="dark">
                          <Tr>
                            <Th>Supplier</Th>
                            <Th>Cl. rate</Th>
                            <Th>Prov. rate</Th>
                            <Th>Target category</Th>
                            <Th>Target supplier fields</Th>
                            <Th>Terms</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {rates.map(
                            ({ id, supplier, client_rate, provider_rate, target_category, target_supplier, terms }) => (
                              <Tr key={id}>
                                <Td>
                                  <SupplierTitle {...supplier} />
                                </Td>
                                <Td>{client_rate}</Td>
                                <Td>{provider_rate}</Td>
                                <Td>{target_category?.name}</Td>
                                <Td>
                                  <SupplierTitle {...target_supplier} />
                                </Td>
                                <Td>{terms}</Td>
                              </Tr>
                            ),
                          )}
                        </Tbody>
                      </Table>
                    ))}
                  </Fieldset>
                )}
                {block_terms.service_rates.length > 0 && (
                  <Fieldset title="Service rates" testId="service-rates" flush>
                    <Table key={id} marginBottom="md" size="sm" textSize="sm" fixed>
                      <colgroup>
                        <col />
                        <col width="160px" />
                        <col width="260px" />
                        <col width="160px" />
                      </colgroup>
                      <Thead variant="dark">
                        <Tr>
                          <Th>Client price</Th>
                          <Th>Target category</Th>
                          <Th>Target supplier fields</Th>
                          <Th>Terms</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {block_terms.service_rates.map(
                          ({ id, client_price, target_category, target_supplier, terms }) => (
                            <Tr key={id}>
                              <Td>{client_price}</Td>
                              <Td>{target_category?.name}</Td>
                              <Td>
                                <SupplierTitle {...target_supplier} />
                              </Td>
                              <Td>{terms}</Td>
                            </Tr>
                          ),
                        )}
                      </Tbody>
                    </Table>
                  </Fieldset>
                )}
                {block_terms.media_discount_rates.length > 0 && (
                  <Fieldset title="Media discount rates" testId="media-discount-rates" flush>
                    <Table key={id} marginBottom="md" size="sm" textSize="sm" fixed>
                      <colgroup>
                        <col />
                        <col width="90px" />
                        <col width="160px" />
                        <col width="260px" />
                        <col width="160px" />
                      </colgroup>
                      <Thead variant="dark">
                        <Tr>
                          <Th>Department</Th>
                          <Th>Cl. disc.</Th>
                          <Th>Prov. disc.</Th>
                          <Th>Target category</Th>
                          <Th>Target supplier fields</Th>
                          <Th>Terms</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {block_terms.media_discount_rates.map(
                          ({
                            id,
                            client_discount,
                            provider_discount,
                            target_department,
                            target_category,
                            target_supplier,
                            terms,
                          }) => (
                            <Tr key={id}>
                              <Td>{target_department.name}</Td>
                              <Td>{client_discount}</Td>
                              <Td>{provider_discount}</Td>
                              <Td>{target_category?.name}</Td>
                              <Td>
                                <SupplierTitle {...target_supplier} />
                              </Td>
                              <Td>{terms}</Td>
                            </Tr>
                          ),
                        )}
                      </Tbody>
                    </Table>
                  </Fieldset>
                )}
                {/* {block_terms.discounts.length > 0 && (
                  <Fieldset title="Discounts" flush testId="media-discounts">
                    {block_terms.discounts.map((dep, idx) => (
                      <Fragment key={idx}>
                        <div className="row py-1" key={idx}>
                          <div className="col-md-2" />
                          <div className="col-md-2 d-flex">
                            <small className="font-weight-bold">
                              <DepartmentTitle {...dep.department} />
                            </small>
                            <span className="ml-auto">{dep.client_discount || '—'}</span>
                          </div>
                        </div>
                        {dep.discounted_products.length > 0 &&
                          dep.discounted_products.map((product) => (
                            <div className="row py-1" key={product.product.id}>
                              <div className="col-md-4" />
                              <div className="col-md-2 d-flex">
                                <small className="font-weight-bold">
                                  <ProductTitle product={product.product} />
                                </small>
                                <span className="ml-auto">{product.client_discount || '—'}</span>
                              </div>
                            </div>
                          ))}
                      </Fragment>
                    ))}
                  </Fieldset>
                )} */}
              </Fieldset>
            ) : (
              <Alert variant="info">No type terms for type</Alert>
            )}
          </div>
        );
      }}
    </ItemLoader>
  );
}
