import type { ReactElement } from 'react';
import { Icon, type IconProp, type IconProps } from 'components/Icon';
import type { ProductKind } from 'types';

const kindIconMap: Record<ProductKind, IconProp> = {
  MEDIA: 'rectangle-ad',
  SERVICE: 'screwdriver-wrench',
  SMART: 'wand-magic-sparkles',
};

type PlanRowKindIconProps = {
  kind: ProductKind;
} & Omit<IconProps, 'icon'>;

export function PlanRowKindIcon({ kind, ...rest }: PlanRowKindIconProps): ReactElement {
  return <Icon icon={kindIconMap[kind]} {...rest} />;
}
