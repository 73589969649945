import type { RenderCellProps } from 'components/DataGrid';
import type { GridRow } from '../types';

import { ReactNode } from 'react';
import { InvoiceBoundState } from 'modules/invoicing/common/components';
import { sepFormat } from 'utilities';
import { Box } from '@ff-it/ui';

export function View({ row, month }: RenderCellProps<GridRow, any> & { month: string }): ReactNode {
  const monthSum = row.months[month];
  if (!monthSum) {
    // HACK:  Never empty for delivery cell
    return ' ';
  }

  const {
    target_client_net_total: target_income,
    planned_client_net_total: planned_income,
    income_state,
    target_provider_net_total: target_expense,
    planned_provider_net_total: planned_expense,
    expense_state,
  } = monthSum;

  return (
    <>
      <Box display="flex" alignItems="center" lineHeight="xs" fontSize="xs">
        <InvoiceBoundState direction="CLIENT" state={income_state} />
        <Box marginLeft="auto">
          {planned_income !== target_income && (
            <small className="mr-1 text-muted" data-testid="planned_income">
              {sepFormat(planned_income)}
            </small>
          )}
          <span data-testid="target_income">{sepFormat(target_income)}</span>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" lineHeight="xs" fontSize="xs">
        <InvoiceBoundState direction="AGENCY" state={expense_state} />
        <Box marginLeft="auto">
          {planned_expense !== target_expense && (
            <small className="mr-1 text-muted" data-testid="planned_expense">
              {sepFormat(planned_expense)}
            </small>
          )}
          <span data-testid="target_expense">{sepFormat(target_expense)}</span>
        </Box>
      </Box>
    </>
  );
}
