import { BaseFieldProps, fieldSubscription, FormGroup, useField } from '@ff-it/form';
import { Box, getControlClassName } from '@ff-it/ui';
import { Icon } from 'components/Icon';
import { useFieldValue } from 'hooks';
import type { EmbeddedClientAgreement } from 'modules/client/agreement/types';
import { ReactElement, useEffect, useId, useRef, useState } from 'react';
import { api } from 'services';
import { required } from 'utilities';

type ClientAgreementFieldProps = Omit<
  BaseFieldProps,
  'placeholder' | 'id' | 'disabled' | 'help' | 'validate' | 'required'
>;

export function ClientAgreementField(props: ClientAgreementFieldProps): ReactElement {
  const { name, className, label, size } = props;
  const client = useFieldValue('client', { allowNull: true });
  const payer = useFieldValue('payer', { allowNull: true });
  const date = useFieldValue('date_from', { allowNull: true });

  const fetchIdRef = useRef(0);

  const {
    input: { value, onChange },
    meta,
  } = useField<EmbeddedClientAgreement | null, HTMLInputElement>(name, {
    subscription: fieldSubscription,
    validate: required,
    allowNull: true,
  });

  const [resolving, setResolving] = useState<boolean>(false);
  // biome-ignore lint/correctness/useExhaustiveDependencies: onChange is table
  useEffect(() => {
    const resolve = async (): Promise<void> => {
      const fetchId = ++fetchIdRef.current;

      let agreement = null;

      if (client != null && date !== null) {
        setResolving(true);
        const res = await api.get<{ agreement: EmbeddedClientAgreement | null }, unknown>(
          'client/agreements/resolve_campaign_agreement/',
          {
            queryParams: {
              client: client.id,
              payer: payer?.id || client.id,
              date,
            },
          },
        );
        if (res.ok) {
          agreement = res.data.agreement;
        }
        setResolving(false);
      }
      if (fetchId === fetchIdRef.current && value?.id !== agreement?.id) {
        onChange(agreement);
      }
    };
    resolve();
  }, [client, payer, date]);

  const id = useId();
  const canBeResolved = client != null && date !== null;

  let content = null;
  if (value) {
    content = value.number;
  } else {
    // can  not be resolved
    if (client == null || date == null) {
      content = <Box color="warning-primary">Set client and period</Box>;
    } else if (!resolving) {
      content = <Box color="error-primary">Failed to resolve agreement</Box>;
    }
  }

  return (
    <FormGroup className={className} label={label} id={id} meta={meta} required>
      <div id={id} className={getControlClassName(size, undefined, meta.invalid && meta.touched ? false : undefined)}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {content}
          {resolving && <Icon icon="loader" />}
        </Box>
        {/* selectable by tests */}
        <input type="hidden" name={name} defaultValue={value?.id} />
      </div>
    </FormGroup>
  );
}
