import { MicroBadge } from 'components/MicroBadge';
import { DepartmentFactor } from './types';
import { Icon } from 'components/Icon';
import { Bar, BarChart, XAxis, Tooltip, ReferenceLine } from 'recharts';
import { Popover } from '@ff-it/ui';
import { months } from 'options';

type RateChartData = { label: string; value: number }[];

const createMonthData = (rates: Record<string, string>): RateChartData =>
  months.map((label, idx) => {
    const key = `_${idx + 1}`;
    return { label: label.substring(0, 3), value: key in rates ? Number.parseFloat(rates[key]) : 0 };
  });

const createWeekData = (rates: Record<string, string>): RateChartData =>
  Array.from(Array(52).keys()).map((_, idx) => {
    const key = `_${idx + 1}`;
    return { label: `${idx + 1}`, value: key in rates ? Number.parseFloat(rates[key]) : 0 };
  });

function RateChart({ data }: { data: RateChartData }) {
  return (
    <BarChart width={280} height={120} data={data}>
      <Tooltip formatter={(v: number) => `${v}%`} />
      <XAxis dataKey="label" style={{ fontSize: '9px' }} />
      <ReferenceLine y={0} stroke="#000" />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  );
}

export function FactorRateBadge({
  factor: { kind, rate, rates },
  portal = false,
}: { factor: DepartmentFactor; portal?: boolean }) {
  switch (kind) {
    case 'CONSTANT':
      return <MicroBadge>{rate}%</MicroBadge>;
    case 'MONTHLY':
    case 'WEEKLY': {
      const numericValues = Object.values(rates).map((value) => Number.parseFloat(value));
      const minValue = Math.min(...numericValues);
      const maxValue = Math.max(...numericValues);

      return (
        <MicroBadge>
          <Popover
            content={<RateChart data={kind === 'MONTHLY' ? createMonthData(rates) : createWeekData(rates)} />}
            interaction="hover"
            portal={portal}
          >
            <span>
              <Icon icon="chart-simple" /> {minValue}-{maxValue}%
            </span>
          </Popover>
        </MicroBadge>
      );
    }
    default:
      throw new Error(`Unknown kind ${kind}`);
  }
}
