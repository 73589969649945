import { SelectField, TextField } from '@ff-it/form';
import { AllActivityTypesField } from 'components';
import { factorKindOptions } from 'core/types';
import { FactorKindRateFields } from 'modules/supplier/factors/FactorKindRateFields';
import { ReactElement } from 'react';
import { required } from 'utilities';

export function Form({ create }: { create: boolean }): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <AllActivityTypesField
          name="type"
          label="Type"
          className="col-md-2"
          disabled={!create}
          required
          validate={required}
        />
        <TextField name="name" label="Name" required validate={required} className="col-4" />
        <SelectField
          name="kind"
          label="Kind"
          required
          validate={required}
          simple
          options={factorKindOptions}
          className="col-2"
        />
      </div>
      <div className="form-row">
        <TextField name="description" label="Description" multiline autosize className="col-6" />
      </div>
      <FactorKindRateFields />
    </div>
  );
}
