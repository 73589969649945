import { ChangeEvent, ReactElement, ReactNode, useId, useState } from 'react';
import cx from 'clsx';
import { editCell, headerCell, input, gridCell, factorsCell } from './style.css';
import { Price } from 'modules/campaign/row/naive';
import { formatDecimalValue, parseDecimalInput, sepFormat } from 'utilities';
import { wrapContainer } from '../style.css';
import { Box, BoxProps, Dialog, DialogTrigger } from '@ff-it/ui';
import { autoFocusAndSelect } from 'components/DataGrid';
import { UpdateHandler } from './useUpdateHandler';
import { usePlanFactors } from '../../../Factors';
import { PlanFactor } from 'modules/campaign/block/types';
import { MicroBadgeSet } from 'components/MicroBadge';
import { FactorRateBadge } from 'modules/supplier/factors';

export function HeaderCell({ children }: { children: ReactNode }) {
  return <div className={headerCell}>{children}</div>;
}

export function PriceCell({
  value,
  fontWeight,
}: { value: string | null; fontWeight?: BoxProps['fontWeight'] }): ReactElement {
  const str = sepFormat(value);
  return (
    <Box className={gridCell} fontWeight={fontWeight}>
      <span title={str} className={wrapContainer}>
        {str}
      </span>
    </Box>
  );
}

export function EditCell({
  price,
  update,
  field,
}: {
  price: Price;
  update: UpdateHandler;
  field: 'fixed_rate' | 'base_rate';
}): ReactElement {
  const storedValue = price[field] || '';

  const [inputState, setInputState] = useState<string>(storedValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const unformattedValue = e.target.value;
    const parsedValue = parseDecimalInput(unformattedValue, 16, 32);

    // update input
    if (parsedValue !== inputState) {
      setInputState(parsedValue);
    }
    update({ [field]: formatDecimalValue(parsedValue) });
  };

  return (
    <div className={cx(editCell, storedValue === '' && 'empty')}>
      <input
        value={inputState}
        key={price.pricing_model}
        ref={autoFocusAndSelect}
        className={input}
        onChange={onChange}
        name={field}
      />
    </div>
  );
}

type DiscountCellProps = {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  extra?: boolean;
};

export function DiscountCell({ value, onChange, extra }: DiscountCellProps): ReactElement {
  const id = useId();
  return (
    <div className={cx(editCell, value === '' && 'empty', extra && 'extra-discount')}>
      <input value={value} onChange={onChange} ref={extra ? undefined : autoFocusAndSelect} className={input} id={id} />
    </div>
  );
}

export function FactorsCell({
  factors,
  factorEditor,
}: {
  factors: number[];
  factorEditor: ReactNode;
}): ReactElement {
  const planFactors = usePlanFactors();
  const mappedFactors = factors.map((factorId) => planFactors.find((pf) => pf.id === factorId) as PlanFactor);

  return (
    <Dialog>
      <DialogTrigger>
        <Box className={cx(factorsCell, factors.length === 0 && 'empty')} data-testid="factor-toggle">
          <MicroBadgeSet>
            {mappedFactors.map((factor) => (
              <FactorRateBadge factor={factor} key={factor.id} portal />
            ))}
          </MicroBadgeSet>
        </Box>
      </DialogTrigger>
      {factorEditor}
    </Dialog>
  );
}
