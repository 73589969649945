import { BoundStates } from 'components';
import { EnterpriseLink } from 'components/EnterpriseLink';
import { ActivityIcon } from 'components/Icon';
import { formatDate, formatDateTime } from 'utilities';
import type { Col, ManagedBlock, Block } from './types';
import { ReactElement } from 'react';
import { useUser } from 'services';

function isBlock(b: ManagedBlock): b is Block {
  return (b as ManagedBlock).block_id !== null;
}

export const PREFIX: Col[] = [
  {
    key: 'block',
    width: 140,
    content: 'Block',
    render: (data) => {
      if (isBlock(data)) {
        const { campaign_id, code, type, block_id } = data;
        return (
          <>
            <ActivityIcon type={type} />
            <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning/${block_id}`} className="ml-2">
              {code}
            </EnterpriseLink>
          </>
        );
      }
      return null;
    },
  },
  {
    key: 'campaign',
    // width: 200,
    content: 'Campaign',
    render: ({ campaign_id, campaign_title }) => (
      <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning`}>{campaign_title}</EnterpriseLink>
    ),
  },
  {
    key: 'client_title',
    width: 150,
    content: 'Client',
  },
];

export const START: Col = {
  key: 'date_from',
  content: 'Start',
  render: (b) => formatDate(b.date_from),
  width: 85,
};

export const END: Col = {
  key: 'date_to',
  content: 'End',
  render: (b) => formatDate(b.date_to),
  width: 85,
};

function ManagerOrPlanners({ manager, planners }: ManagedBlock): ReactElement {
  const user = useUser();
  if (manager.id === user.id) {
    return <>{planners.map(({ first_name, last_name }) => `${first_name} ${last_name}`).join(', ')}</>;
  }
  return <>{`${manager.first_name} ${manager.last_name}`}</>;
}

export const PLANNERS: Col = {
  key: 'planners',
  content: 'Manager / Planners',
  render: (b) => <ManagerOrPlanners {...b} />,
  cellProps: { textAlign: 'end' },
};

export const DEADLINE: Col = {
  key: 'deadline',
  content: 'Deadline',
  render: (b) => b.deadline && formatDate(b.deadline),
  width: 85,
};

export const DAYS_UNTIL_START: Col = {
  key: 'days_until_start',
  content: 'Days until start',
  cellProps: { textAlign: 'end' },
};

export const DAYS_TO_PLAN: Col = {
  key: 'days_to_plan',
  content: 'Days to plan',
  cellProps: { textAlign: 'end' },
};

export const DAYS_TO_PLAN_LEFT: Col = {
  key: 'days_to_plan_left',
  content: 'Days left',
  cellProps: { textAlign: 'end' },
};

export const BRIEF_SENT: Col = {
  key: 'plannted_at',
  content: 'Brief sent',
  render: (b) => b.planned_at && formatDateTime(b.planned_at),
};

export const DAYS_UNTIL_END: Col = {
  key: 'days_until_end',
  content: 'Days until end',
  cellProps: { textAlign: 'end' },
};

export const DAYS_SINCE_END: Col = {
  key: 'days_since_end',
  content: 'Days since end',
  cellProps: { textAlign: 'end' },
};

export const BOUND_STATES: Col = {
  key: 'bound_state',
  width: '50px',
  render: (b) => (b.bound_state ? <BoundStates {...b.bound_state} /> : null),
  cellProps: { textAlign: 'center' },
};
