import { Box, Popover } from '@ff-it/ui';
import { Icon } from 'components/Icon';
import { helpBtn, popoverCode, popoverItem, popoverList } from './style.css';

const content = (
  <Box padding="md" maxWidth="xs">
    <Box fontWeight="medium" fontSize="md" marginBottom="sm">
      Pricing strategy
    </Box>
    <ul className={popoverList}>
      <li className={popoverItem}>
        <strong>Discount</strong> - Adds the discount to the gross price to calculate the net price.
        <code className={popoverCode}>Net price = Gross × Factors × Discounts</code>
      </li>
      <li className={popoverItem}>
        <strong>Fixed net</strong> - Enter a fixed net price. This option ignores factors.
        <code className={popoverCode}>Net price = Fixed net</code>
      </li>
      <li className={popoverItem}>
        <strong>Custom gross</strong> - Enter a custom gross price. Factors will be applied.
        <code className={popoverCode}>Net price = Custom gross × Factors × Discounts</code>
      </li>
    </ul>
    <hr />
    <code className={popoverCode}>Effective discount = 1 - Net price / (Gross × Factors)</code>
  </Box>
);

export const HelpPopover = () => {
  return (
    <Popover content={content} interaction="click">
      <button type="button" tabIndex={-1} className={helpBtn} title="Help">
        <Icon icon="circle-question" />
      </button>
    </Popover>
  );
};
