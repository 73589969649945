import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { useModel } from './context';
import { Button } from '@ff-it/ui';
import { Perm, useHasPerms } from 'core/permissions';
import { PermissionDenied } from './errors';
import { Breadcrumb, Page, Heading } from '../layout';
import { Icon } from '../Icon';
import { Link } from 'react-router-dom';
import { IndexTable } from 'components/ControlledTable';
import { IndexTableProps } from 'components/ControlledTable/IndexTable';

type IndexContainerProps = {
  breadCrumb?: ReactNode | null;
  toolbar?: ReactNode;
  title?: ReactNode;
  hasCreate?: boolean;
  before?: ReactNode;
  after?: ReactNode;
} & Omit<IndexTableProps<any>, 'before'>;

export function IndexContainer({
  title,
  breadCrumb = 'Index',
  hasCreate,
  toolbar,
  before,
  ...props
}: IndexContainerProps): ReactElement {
  return (
    <Page className={cx('scene scene-index')}>
      {breadCrumb && <Breadcrumb to={null}>{breadCrumb}</Breadcrumb>}
      <IndexTable
        {...props}
        before={
          <>
            <Heading title={title}>
              <div className="btn-toolbar">
                {toolbar}
                {hasCreate && (
                  <Button variant="outline-primary" size="sm" to="create" component={Link}>
                    Create <Icon className="ml-1" icon="circle-plus" />
                  </Button>
                )}
              </div>
            </Heading>
            {before}
          </>
        }
      />
    </Page>
  );
}

type IndexSceneProps = {
  perm?: Perm;
  canCreate?: Perm;
} & Omit<IndexContainerProps, 'url' | 'hasCreate'>;

export function IndexScene({ perm, canCreate, ...rest }: IndexSceneProps): ReactElement {
  const { endpoint, permissions, title: _, pluralTitle } = useModel();

  const [hasIndex, hasCreate] = useHasPerms(perm || permissions?.view, canCreate || permissions?.add);
  if (!hasIndex) {
    return <PermissionDenied />;
  }

  return <IndexContainer url={endpoint} hasCreate={hasCreate} title={pluralTitle} {...rest} />;
}
