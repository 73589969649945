import { ReactElement, ReactNode } from 'react';
import type { RenderCellProps } from 'components/DataGrid';
import { BoundStates } from 'components';
import { Icon } from 'components/Icon';
import { Box, Button } from '@ff-it/ui';
import { useGroupToggle } from '../hooks/useToggleGroup';
import type { GridGroup } from '../types';
import { createColumn } from './createColumn';
import { flush, groupToggleBtn } from './style.css';
import { useBlockSums } from '../../useBlockSums';

function StateHeader(): ReactNode | null {
  const data = useBlockSums();
  if (data) {
    return <BoundStates {...data} />;
  }
  return null;
}

function GroupExpandToggle({ row, tabIndex }: RenderCellProps<GridGroup, any>): ReactElement {
  const [isExpanded, toggleGroup] = useGroupToggle();
  return (
    <Button
      testId="toggle-group"
      onClick={() => toggleGroup(row._key)}
      tabIndex={tabIndex}
      className={groupToggleBtn}
      variant={null}
      size={null}
    >
      <Icon icon={isExpanded ? 'folder-open' : 'folder-closed'} />
    </Button>
  );
}

export const StateColumn = createColumn(
  {
    key: 'state',
    width: 46,
    frozen: true,
    className: flush,
    name: <StateHeader />,
  },
  {
    renderView: ({ row: { id: rowId, state } }) =>
      state.canceled ? null : <BoundStates className="d-block mt-1" {...state} row={rowId} />,
  },
  {
    renderView: (props) =>
      props.row.id === 0 ? (
        <Box color="quarterary">
          <Icon icon="folder-grid" />
        </Box>
      ) : props.row.empty ? (
        <Box color="quarterary">
          <Icon icon="folder-xmark" />
        </Box>
      ) : (
        <GroupExpandToggle {...props} />
      ),
  },
);
