import { Badge, Period, TabNav } from 'components';
import { ActivityIcon } from 'components/Icon';
import { BlockActions } from '../BlockActions';
import { BlockDetails } from '../types';
import type { SubNavItem } from 'components';
import { fmtPeriodDistance, formatDate } from 'utilities';
import { PlanAttachments } from './PlanAttachment';
import { ReactElement } from 'react';
import { BriefButton } from './Brief';
import { CampaignDetails } from 'modules/campaign/campaign/types';

const nav: SubNavItem[] = [
  {
    end: true,
    to: '',
    children: 'Plan',
  },
  {
    to: 'agreement',
    children: 'Agreement',
  },
  {
    to: 'history',
    children: 'History',
  },
];

export function BlockHeader({
  block,
  endpoint,
  campaign,
}: {
  block: BlockDetails;
  endpoint: string;
  campaign: CampaignDetails;
}): ReactElement {
  const { state, type, title, code, date_from, date_to, deadline, planners } = block;

  return (
    <div className="container-fluid header header--block">
      <div className="d-flex flex-row align-items-center mt-1 mb-1">
        {/* <Box marginTop="none" fontSize="md"> */}
        <h3 className="mt-0">
          <small>
            <ActivityIcon type={type} />
          </small>{' '}
          <strong>{type}</strong> {title}{' '}
          <span className="text-muted">
            <small>#{code}</small>
          </span>
        </h3>
        {/* </Box> */}
        <Badge state={state} dataTestId="block-state" className="ml-2" />

        <dl className="ml-auto">
          <dt>
            <Period date_from={date_from} date_to={date_to} flat />
          </dt>
          <dd className="text-muted">{fmtPeriodDistance({ date_from, date_to })}</dd>
          <dt>deadline</dt>
          <dd className="font-weight-bold">{formatDate(deadline)}</dd>
          <dt>planned by</dt>
          <dd>{planners.map(({ first_name, last_name }) => `${first_name} ${last_name.charAt(0)}`).join(', ')}</dd>
        </dl>
      </div>

      <div className="d-flex flex-row">
        <TabNav nav={nav}>
          <li className="nav-item">
            <BriefButton className="nav-link" />
          </li>
          <li className="nav-item">
            <PlanAttachments endpoint={endpoint} className="nav-link" />
          </li>
        </TabNav>
        <div className="mx-auto d-flex"></div>
        <BlockActions campaign={campaign} />
      </div>
    </div>
  );
}
