/**
 * Parses input value to valid decimal string values.
 * Clamps total digites and precision.
 * Includes incomplete non-normalized values like: '.', '-.', '.0'
 */
export const parseDecimalInput = (v: string, decimalPlaces?: number, maxDigits?: number): string => {
  // Check for negative sign
  const negative = v.startsWith('-');

  // Preserve decimal point for special cases
  const hasDecimalPoint = v.includes('.');

  // Normalize and clean the string
  const cleaned = v.replace(/,/g, '.').replace(/[^\d.]/g, '');

  // Handle special incomplete cases
  if (!cleaned) return negative ? '-' : '';
  if (cleaned === '.') return negative ? '-.' : '.';

  // Split into integer and decimal parts
  const parts = cleaned.split('.');
  const intPart = parts[0] || '';
  const decPart = parts.length > 1 ? parts[1] : '';

  // Handle maxDigits constraint (total digits across both parts)
  let limitedIntPart = intPart;
  let limitedDecPart = decPart;

  if (maxDigits !== undefined) {
    const totalDigits = intPart.length + decPart.length;
    if (totalDigits > maxDigits) {
      // Prioritize integer part
      const intDigitsToKeep = Math.min(intPart.length, maxDigits);
      limitedIntPart = intPart.slice(0, intDigitsToKeep);

      // Use remaining digit budget for decimal part
      const remainingDigits = maxDigits - intDigitsToKeep;
      limitedDecPart = decPart.slice(0, remainingDigits);
    }
  }

  // Apply precision constraint to decimal part if needed
  if (decimalPlaces !== undefined) {
    limitedDecPart = limitedDecPart.slice(0, decimalPlaces);
  }

  // Build result with proper formatting for incomplete values
  let result;
  if (hasDecimalPoint) {
    result = `${limitedIntPart}.${limitedDecPart}`;
  } else {
    result = limitedIntPart;
  }

  return negative ? `-${result}` : result;
};

/**
 * Takes pared decimal input and converts to valid serializable value:
 * - In complete values are turned into empty value
 * - Empty values are represented by null
 * - Insignificant leading zeros are trimmed
 * - Numbers are padded to decimalPlaces, if decimal places is -1 trailing zeros are trimmed
 */
export function formatDecimalValue(v: string, decimalPlaces?: number): string | null {
  // Handle empty values
  if (!v || v === '-' || v === '.' || v === '-.') {
    return null;
  }

  const isNegative = v.startsWith('-');
  const absValue = isNegative ? v.substring(1) : v;

  // Split into integer and decimal parts
  const [intPart = '', decPart = ''] = absValue.split('.');

  // Trim leading zeros from integer part (but keep a single zero for values < 1)
  const trimmedIntPart = intPart.replace(/^0+/, '') || '0';

  // Handle decimal places
  let formattedDecPart = '';
  if (decimalPlaces === undefined) {
    // Keep decimal part as is if decimalPlaces is not specified
    formattedDecPart = decPart ? '.' + decPart : '';
  } else if (decimalPlaces === -1) {
    // Trim trailing zeros when decimalPlaces is -1
    formattedDecPart = decPart.replace(/0+$/, '');
    // If we have a decimal part after trimming, add the decimal point
    if (formattedDecPart) {
      formattedDecPart = '.' + formattedDecPart;
    }
  } else if (decimalPlaces >= 0) {
    // Pad or truncate to exact decimal places
    const paddedDecPart = decPart.padEnd(decimalPlaces, '0').substring(0, decimalPlaces);
    formattedDecPart = '.' + paddedDecPart;
  }

  // Combine parts
  const result = trimmedIntPart + formattedDecPart;

  // Add negative sign if needed
  return isNegative ? '-' + result : result;
}

export function parseAndFormatDecimal(
  input: string,
  normalize = false,
  decimalPlaces?: number,
  maxDigits?: number,
): string | null {
  const parsed = parseDecimalInput(input, decimalPlaces, maxDigits);
  return formatDecimalValue(parsed, normalize ? -1 : decimalPlaces);
}
