import { SetStateAction, Dispatch, ReactElement } from 'react';
import { Button } from '@ff-it/ui';
import { Icon } from '../../Icon';
import './TailToggler.scss';

interface TailToggleProps {
  count?: number;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export function TailToggler({ count, show, setShow }: TailToggleProps): ReactElement {
  return (
    <Button variant="outline-primary" className="tail-toggler" onClick={() => setShow((s) => !s)}>
      <span className="mr-1">
        {count && <strong>{count}</strong>} {show ? 'less' : 'more'}
      </span>
      <Icon icon={`chevron-${show ? 'up' : 'down'}` as any} />
    </Button>
  );
}
