import { Link } from 'react-router-dom';
import { EntityAction, createConfirmDialog } from 'components';
import type { Ticket } from 'modules/support/types';
import { sessionUser, checkPerm } from 'core/permissions';
import companyPerms from 'modules/core/company/permissions';
import departmentPerms from 'modules/supplier/department/permissions';
import clientPerms from 'modules/client/permissions';
import agreementPerms from 'modules/client/agreement/permissions';

const dialog = createConfirmDialog();

const actions: EntityAction<Ticket>[] = [
  ({
    item: {
      id,
      assignee,
      enterprise: { slug },
    },
  }) => ({
    action: 'create_company',
    permKey: (sess) => {
      // assinge or superuser and can add company
      const u = sessionUser(sess);
      return (u?.is_superuser || u?.id === assignee?.id) && checkPerm(sess, companyPerms.add);
    },
    button: {
      children: 'Create company',
      variant: 'primary',
      component: Link,
      to: `/${slug}/preferences/companies/from_ticket/${id}`,
      target: '_blank',
    },
    visible: ({ state, category }) => state === 'OPEN' && category === 'ADD_COMPANY',
  }),

  ({
    item: {
      assignee,
      enterprise: { slug },
    },
  }) => ({
    action: 'create_department',
    permKey: (sess) => {
      // assinge or superuser and can view agreement
      const u = sessionUser(sess);
      return (u?.is_superuser || u?.id === assignee?.id) && checkPerm(sess, departmentPerms.view);
    },
    button: {
      children: 'Departments',
      variant: 'primary',
      component: Link,
      to: `/${slug}/preferences/departments`,
      target: '_blank',
    },
    visible: ({ state, category }) => state === 'OPEN' && category === 'ADD_DEPARTMENT',
  }),
  ({
    item: {
      id,
      assignee,
      enterprise: { slug },
      data,
    },
  }) => ({
    action: 'create_client_agreement',
    permKey: (sess) => {
      // assinge or superuser and can view client and add agreement
      const u = sessionUser(sess);
      return (
        (u?.is_superuser || u?.id === assignee?.id) &&
        checkPerm(sess, clientPerms.view) &&
        checkPerm(sess, agreementPerms.add)
      );
    },
    button: {
      children: 'Create agreement',
      variant: 'primary',
      component: Link,
      to: `/${slug}/contacts/clients/${data?.company?.id}/agreements/from_ticket/${id}`,
      target: '_blank',
    },
    visible: ({ state, category }) => state === 'OPEN' && category === 'ADD_CLIENT',
  }),
  ({ item: { assignee } }) => ({
    action: 'close',
    permKey: (sess) => {
      // assinge or superuser
      const u = sessionUser(sess);
      return u?.is_superuser || u?.id === assignee?.id;
    },
    dialog,
    button: {
      children: 'Close ticket',
      variant: 'outline-primary',
    },
    visible: ({ state }) => state === 'OPEN',
  }),
  ({ item: { author } }) => ({
    action: 'cancel',
    permKey: (sess) => {
      // author or superuser
      const u = sessionUser(sess);
      return u?.is_superuser || u?.id === author?.id;
    },
    dialog,
    button: {
      children: 'Cancel ticket',
      variant: 'outline-primary',
    },
    visible: ({ state }) => state === 'OPEN',
  }),
];

export default actions;
