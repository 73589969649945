import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { SelectField, TextField } from '@ff-it/form';
import {
  AllActivityTypesField,
  CreateScene,
  DeleteScene,
  EntityScene,
  ModelScene,
  IndexScene,
  UpdateScene,
} from 'components';
import permissions from 'modules/supplier/department/permissions';
import { DepartmentForm, Products, Factors, Nav } from 'modules/supplier/department';
import type { ListDepartment } from 'modules/supplier/department/types';
import { NewListExport, ListImport } from 'components/ListActions';
import { productKindOptions } from 'options';
import { Alert, Box, TableColumns } from '@ff-it/ui';

const columns: TableColumns<ListDepartment> = [
  {
    content: 'Department set',
    key: 'departmentset.name',
    sortable: true,
    width: 180,
  },
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    width: 350,
    render: ({ id, name }: ListDepartment): ReactElement => <Link to={`${id}`}>{name}</Link>,
  },
  {
    content: 'Category',
    key: 'category.id',
    render: ({ category }: ListDepartment): ReactNode => (category ? category.name : null),
  },
];

const initialValues = {
  name: '',
  category: null,
};

const model = {
  endpoint: 'supplier/departments/',
  title: 'Department',
  pluralTitle: 'Departments',
  entityTitle: ({ name }: ListDepartment) => name,
  permissions,
};

export const route = {
  path: 'departments',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          filterFields={
            <>
              <TextField name="search" placeholder="Search.." size="sm" />
              <SelectField
                name="kind"
                placeholder="Kind"
                options={productKindOptions}
                simple
                className="ml-1"
                size="sm"
              />
              <AllActivityTypesField name="type" placeholder="Type" className="ml-1" size="sm" />
            </>
          }
          toolbar={
            <>
              <ListImport
                permission={permissions.import_products}
                endpoint={`${model.endpoint}import_products/`}
                dialogChildren={
                  <Alert variant="warning" className="py-0 px-1">
                    <Box fontSize="xs">
                      Upserts products and depratments by names, kind and type. <br />
                      Updates gross price for existing
                    </Box>
                  </Alert>
                }
              >
                Product import
              </ListImport>
              <NewListExport permission={permissions.export_products} endpoint={`${model.endpoint}export_products/`}>
                Product export
              </NewListExport>
            </>
          }
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <DepartmentForm />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <DepartmentForm />
            </UpdateScene>
          ),
        },
        {
          path: 'products/*',
          element: <Products />,
        },
        {
          path: 'factors/*',
          element: <Factors />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
