import { RenderCellProps } from 'components/DataGrid';
import { createColumn } from './createColumn';
import { GridRow } from '../types';
import { ReactNode } from 'react';
import { center } from './style.css';

export function viewSum(props: RenderCellProps<GridRow, any>): ReactNode {
  const value = props.row.sums.planned.quantity;
  if (value == null) {
    return null;
  }

  return (
    <div data-testid="planned" title="Planned quantity">
      {value}
    </div>
  );
}

export const SumQuantityColumn = createColumn(
  {
    key: 'quantity',
    name: 'Quantity',
    width: 76,
    className: center,
  },
  {
    renderView: (props) => (props.row.kind === 'SMART' ? null : viewSum(props)),
  },
);
