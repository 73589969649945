import { ReactElement, useLayoutEffect, useRef, useState } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { Toolbar } from './Toolbar';
import { PlanState, PlanSelection } from './types';
import { Grid, GridHandle } from './Grid';
import { gridContainer } from './styles.css';
import { RequestFailure } from '@ff-it/api';
import { LoadingContainer, ModalContainer } from '@ff-it/ui';
import { useRequestHandler } from '../../useRequestHandler';
import { Fetcher } from 'services';
import { PlanControllerProvider } from './usePlanController';
import { useEntity } from 'components';
import { BlockDetails } from '../../types';
import { strategies } from '../../strategy';
import { prompStale } from 'utilities';
import { useQuery } from 'hooks';
import { PlanFactorsProvider } from './Factors';
import { SmartOptionsProivder } from './useSmartOptions';

type PlanContainerProps = {
  planState: PlanState;
  requestHandler: Fetcher;
  mutate: KeyedMutator<PlanState>;
};

function PlanContainer({ planState, requestHandler, mutate }: PlanContainerProps): ReactElement {
  const gridRef = useRef<GridHandle>(null);

  const query = useQuery();
  const restored = useRef(null);
  useLayoutEffect(() => {
    const rowId = query.get('rowId');
    if (rowId) {
      if (rowId !== restored.current) {
        gridRef.current?.selectCell(`row-${rowId}`, 'handle');
        restored.current === rowId;
      }
    }
  }, [query]);

  const [planSelection, setPlanSelection] = useState<PlanSelection>(null);

  const strategy = strategies[planState.plan_strategy];
  return (
    <PlanControllerProvider
      planning={planState.planning}
      locked={planState.locked}
      strategy={strategy}
      requestHandler={requestHandler}
      mutate={mutate}
      gridRef={gridRef}
    >
      <PlanFactorsProvider value={planState.factors}>
        <SmartOptionsProivder planRows={planState.rows}>
          <ModalContainer scope="plan" />
          <div
            className="container-fluid flex-grow-1 d-flex flex-column"
            data-testid={`plan-${planState.id}`}
            data-planning={planState.planning}
          >
            <Toolbar planState={planState} planSelection={planSelection} setPlanSelection={setPlanSelection} />
            <div className={gridContainer}>
              <Grid
                planState={planState}
                planSelection={planSelection}
                setPlanSelection={setPlanSelection}
                ref={gridRef}
              />
            </div>
          </div>
        </SmartOptionsProivder>
      </PlanFactorsProvider>
    </PlanControllerProvider>
  );
}

export function Plan(): ReactElement {
  const {
    item: { id, etag },
  } = useEntity<BlockDetails>();
  const requestHandler = useRequestHandler();

  const {
    data: planState,
    isLoading,
    isValidating,
    error,
    mutate,
  } = useSWR<PlanState, RequestFailure<unknown>>(
    () => ({
      method: 'GET',
      url: `plan/`,
      _block_id: id,
      // FIXME: External block changes: price update toggles locks, planning state changes toolbar, etc.
      _block_etag: etag,
    }),
    {
      fetcher: requestHandler,
      shouldRetryOnError: false,
      onError: (error) => {
        if (error.status === 412) {
          prompStale();
        }
        throw error;
      },
    },
  );

  if (error) {
    throw error;
  }

  return (
    <LoadingContainer loading={isLoading || isValidating} className="flex-grow-1 flex-column d-flex">
      {planState && <PlanContainer requestHandler={requestHandler} mutate={mutate} planState={planState} />}
    </LoadingContainer>
  );
}
