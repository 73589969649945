import type { Application } from 'types';
import type { RowCommonFields } from './common';
import { createOptions } from 'options';

export const directions = ['AGENCY', 'CLIENT', 'BOTH'] as const;
export type Direction = (typeof directions)[number];
export const directionOptions = createOptions<Direction>(directions);

export type SmartFields = {
  id: number;
  direction: Direction | null;
  application: Application | null;
  rate: {
    client: string | null;
    provider: string | null;
  };
  naive: readonly number[];
};

export type SmartRow = RowCommonFields<'SMART'> & SmartFields;
