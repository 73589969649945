import { ReactElement, Dispatch, SetStateAction } from 'react';
import type { Enterprise } from 'modules/core/types';
import { ItemLoader, UpdateContainer, useEntity } from 'components';
import { Icon } from 'components/Icon';
import { Box, Button } from '@ff-it/ui';
import { FileField } from '@ff-it/form';
import { api } from 'services';

interface Dimensions {
  width: number;
  height: number;
}

function Placehder({ width, height }: Dimensions): ReactElement {
  return (
    <Box
      padding="xs"
      boxShadow="lg"
      display="inline-block"
      border="default"
      borderRadius="xs"
      background="secondary-solid"
    >
      <svg
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        textAnchor="middle"
      >
        <rect width="100%" height="100%" fill="#868e96"></rect>
        <text x="50%" y="50%" fill="#dee2e6">
          {width}x{height}
        </text>
      </svg>
    </Box>
  );
}

function Image({ width, height, src }: Dimensions & { src: string }): ReactElement {
  return (
    <Box
      padding="xs"
      boxShadow="lg"
      display="inline-block"
      border="default"
      borderRadius="xs"
      background="secondary-solid"
    >
      <img src={src} width={width} height={height} />
    </Box>
  );
}

interface Logos {
  logo_url: string | null;
  invoice_logo_url: string | null;
}

interface DeleteControlProps {
  endpoint: string;
  field: 'logo' | 'invoice_logo';
  setLogos: Dispatch<SetStateAction<Logos>>;
}
function DeleteControl({ field, endpoint, setLogos }: DeleteControlProps): ReactElement {
  return (
    <Button
      variant="danger"
      type="button"
      size="sm"
      style={{
        position: 'absolute' as const,
        right: 0,
        top: 0,
      }}
      onClick={() => {
        if (confirm('Are you sure you want to delete this logo?')) {
          api.delete(`${endpoint}logos/${field}/`).then((res) => {
            if (res.ok) {
              setLogos((logos) => {
                return { ...logos, [`${field}_url`]: null };
              });
            } else {
              throw res.error;
            }
          });
        }
      }}
    >
      <Icon icon="xmark" />
    </Button>
  );
}

export function Logos(): ReactElement {
  const { endpoint } = useEntity<Enterprise>();

  const url = `${endpoint}logos/`;
  return (
    <ItemLoader<Logos> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer
          url={url}
          initialValues={item}
          updateItem={setItem}
          method="POST"
          breadcrumb="Logos"
          mapValues={(values) => {
            const fd = new FormData();
            for (const [key, value] of Object.entries(values)) {
              if (value) {
                fd.append(key, value as any);
              }
            }
            return fd;
          }}
        >
          <div className="container px-0 ml-0">
            <fieldset>
              <legend>Brand</legend>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    {item.logo_url ? (
                      <div className="position-relative d-inline-block">
                        <Image src={item.logo_url} width={200} height={60} />
                        <DeleteControl endpoint={endpoint} field="logo" setLogos={setItem} />
                      </div>
                    ) : (
                      <Placehder width={200} height={60} />
                    )}
                  </div>

                  <FileField name="logo" label="Brand logo" />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Invoice</legend>
              <div className="row">
                <div className="col-6">
                  <div className="mb-3">
                    {item.invoice_logo_url ? (
                      <div className="position-relative d-inline-block">
                        <Image src={item.invoice_logo_url} width={400} height={120} />
                        <DeleteControl endpoint={endpoint} field="invoice_logo" setLogos={setItem} />
                      </div>
                    ) : (
                      <Placehder width={400} height={120} />
                    )}
                  </div>
                  <FileField name="invoice_logo" label="Invoice logo" />
                </div>
              </div>
            </fieldset>
          </div>
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}
