import { ReactElement, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { Box, TableColumns, openModal } from '@ff-it/ui';
import { useRoutes, Link, useNavigate } from 'react-router-dom';
import {
  AllActivityTypesField,
  CreateScene,
  DeleteScene,
  EntityAction,
  EntityFormActions,
  EntityScene,
  ModelScene,
  IndexScene,
  UpdateScene,
  useEntity,
} from 'components';

import type { DepartmentProduct } from 'modules/supplier/products';
import permissions from '../permissions';
import { Form } from './Form';
import { Nav } from './Nav';
import { MoveDepartmentDialog } from './MoveDepartmentDialog';
import type { Department } from '../types';
import { SelectField, TextField } from '@ff-it/form';
import { productKindOptions } from 'options';
import { Icon } from 'components/Icon';

function ProductOrInheritedCategory(product: DepartmentProduct): ReactNode {
  const { item } = useEntity<Department>();
  if (product.category) {
    return product.category.name;
  }
  if (item.category) {
    return (
      <Box color="quarterary" title="Inherited from department">
        <em>{item.category.name}</em>
      </Box>
    );
  }
  return null;
}

const columns: TableColumns<DepartmentProduct> = [
  {
    content: 'Type',
    key: 'type',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    width: 350,
    render: ({ id, name }) => <Link to={`${id}`}>{name || <em>Generic product</em>}</Link>,
  },
  {
    content: 'Category',
    key: 'category.id',
    width: 350,
    render: (product: DepartmentProduct) => <ProductOrInheritedCategory {...product} />,
  },
  {
    content: 'Kind',
    key: 'kind',
    sortable: true,
    width: 100,
  },
  {
    content: 'Unit',
    key: 'unit',
    width: 100,
  },
  {
    content: 'Direction',
    key: 'direction',
  },
  {
    content: 'Application',
    key: 'application',
  },
  {
    width: 100,
    content: 'Gross price',
    key: 'gross_price',
    cellProps: {
      textAlign: 'right',
    },
  },
  {
    width: 100,
    content: 'Enabled',
    key: 'disabled',
    cellProps: {
      textAlign: 'right',
    },
    render: ({ disabled }) => (
      <Icon className={!disabled ? 'text-success' : 'text-danger'} icon={!disabled ? 'check' : 'xmark'} />
    ),
  },
];

const initialValues = {
  // general
  kind: null,
  type: null,
  name: '',
  category: null,
  activities: [],
  // media or service
  unit: null,

  // media
  gross_price: null,

  // smart
  application: null,
  direction: null,

  // specialization
  configuration: null,
  //
  disabled: false,
};

function ProductActions(props: { departmentId: number }): ReactElement {
  const { endpoint } = useEntity();
  const navigate = useNavigate();
  const actions: EntityAction<DepartmentProduct>[] = [
    {
      action: 'move',
      permKey: 'change',
      button: {
        icon: 'turn-up',
        children: 'Move',
        variant: 'outline-secondary',
        size: 'sm',
        onClick: () =>
          openModal<Department>((dialogProps) => (
            <MoveDepartmentDialog {...dialogProps} {...props} endpoint={endpoint} />
          )).then((department) => {
            if (department) {
              toast.success('Product has been moved');
              navigate(`../../../${department.id}/products`);
            }
          }),
      },
    },
  ];

  return <EntityFormActions className="ml-5" actions={actions} />;
}

export function Products(): ReactElement {
  const {
    item: { id: departmentId },
  } = useEntity();

  const routes = useRoutes([
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          filterFields={
            <>
              <TextField name="search" placeholder="Search.." size="sm" />
              <SelectField
                name="kind"
                placeholder="Kind"
                options={productKindOptions}
                simple
                className="ml-1"
                size="sm"
              />
              <AllActivityTypesField name="type" placeholder="Type" className="ml-1" size="sm" />
              <SelectField
                name="disabled"
                placeholder="Disabled"
                className="ml-1"
                size="sm"
                options={[
                  { label: 'Disabled', value: 'true' },
                  { label: 'Enabled', value: 'false' },
                ]}
                simple
              />
            </>
          }
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form create />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene
              viewDisabled
              heading={null}
              actions={<ProductActions departmentId={departmentId} />}
              copy={(product) => ({ ...product, id: undefined })}
            >
              <Form create={false} />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return (
    <ModelScene
      endpoint={`supplier/departments/${departmentId}/products/`}
      title="Product"
      pluralTitle="Products"
      entityTitle={({ name }: DepartmentProduct) => name || <em>Unspecified product</em>}
      // @TODO
      permissions={permissions}
    >
      {routes}
    </ModelScene>
  );
}
