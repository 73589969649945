import { Period } from 'components';
import { createColumn } from './createColumn';

export const PeriodColumn = createColumn(
  {
    key: 'period',
    name: 'Period',
    width: 84,
  },
  {
    renderView: ({
      row: {
        sums: {
          planned: { date_from, date_to },
        },
      },
    }) => <Period date_from={date_from} date_to={date_to} />,
  },
);
