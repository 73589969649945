import { Box } from '@ff-it/ui';

import { DepartmentFactor, FactorCard } from 'modules/supplier/factors';
import { factorToggle, optionCard, optionContainer, optionControls } from './RowFactorsDialog.css';
import { ChangeEvent, ReactNode } from 'react';
import { Checkbox } from 'components/CheckBox';
import { PlanFactor } from 'modules/campaign/block/types';

export type FactorOptionProps = {
  factor: DepartmentFactor;
  children?: ReactNode;
  clientChecked: boolean;
  onClientChange?: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  providerChecked: boolean;
  onProviderChange?: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export function FactorOption({
  factor,
  isPlan,
  children,
  clientChecked,
  providerChecked,
  onToggle,
  isClientLocked,
  isProviderLocked,
}: {
  factor: DepartmentFactor | PlanFactor;
  isPlan: boolean;
  children?: ReactNode;
  clientChecked: boolean;
  providerChecked: boolean;
  onToggle: (
    factorId: number,
    direction: 'client_factors' | 'provider_factors',
    isChecked: boolean,
    departmentFactor?: DepartmentFactor,
  ) => Promise<void>;
  isClientLocked: boolean;
  isProviderLocked: boolean;
}) {
  const handleClientChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isClientLocked) return;
    await onToggle(
      isPlan ? factor.id : -1, // Use -1 as a sentinel for department factors
      'client_factors',
      e.target.checked,
      isPlan ? undefined : factor,
    );
  };

  const handleProviderChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isProviderLocked) return;
    await onToggle(
      isPlan ? factor.id : -1, // Use -1 as a sentinel for department factors
      'provider_factors',
      e.target.checked,
      isPlan ? undefined : factor,
    );
  };

  return (
    <div className={optionContainer} data-testid={`${isPlan ? 'plan' : 'department'}-factor-${factor.id}`}>
      <div className={optionCard}>
        <FactorCard factor={factor} />
      </div>
      <div className={optionControls}>
        {children}
        <Box className={isClientLocked ? factorToggle.locked : factorToggle.income}>
          <Checkbox checked={clientChecked} onChange={handleClientChange} disabled={isClientLocked} />
        </Box>
        <Box className={isProviderLocked ? factorToggle.locked : factorToggle.expense}>
          <Checkbox checked={providerChecked} onChange={handleProviderChange} disabled={isProviderLocked} />
        </Box>
      </div>
    </div>
  );
}
