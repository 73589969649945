import { ReactElement } from 'react';
import { Link, useRoutes } from 'react-router-dom';
import type { AVB } from 'modules/guarantee/types';
import { useEntity, withHasPerm, Breadcrumb, IndexContainer } from 'components';
import { Icon } from 'components/Icon';
import { columns } from 'routes/Agency/Invoices/Income';
import permissions from 'modules/guarantee/avb/permissions';
import { Button } from '@ff-it/ui';
import { AVBInvoiceBuilder } from 'modules/linker';

export const Income = withHasPerm(function Income(): ReactElement {
  const { endpoint } = useEntity<AVB>();

  const routes = useRoutes([
    {
      path: '',
      element: (
        <IndexContainer
          url={`${endpoint}income/`}
          columns={columns}
          title="Income"
          filterFields={null}
          toolbar={
            <Button variant="outline-primary" size="sm" to="create" component={Link}>
              Create Income <Icon className="ml-1" icon="circle-plus" />
            </Button>
          }
        />
      ),
    },
    {
      // @TODO perm?
      path: 'create',
      element: <AVBInvoiceBuilder />,
    },
  ]);
  return (
    <>
      <Breadcrumb>Income</Breadcrumb>
      {routes}
    </>
  );
}, permissions.invoice);
