import { ReactNode, ReactElement } from 'react';
import {
  autoPlacement,
  FloatingOverlay,
  FloatingPortal,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  autoUpdate,
} from '@floating-ui/react';
import { dropdownAnchor, dropdownContainer } from './styles.css';
import { Box } from '@ff-it/ui';
import { trapEnterHandler } from './utils';

type CellDropdownProps = {
  testId?: string;
  children: ReactNode;
  onRequestClose: () => void;
  trapEnter?: boolean;
};

export function CellDropdown({ children, onRequestClose, trapEnter }: CellDropdownProps): ReactElement {
  const { refs, floatingStyles, context } = useFloating({
    open: true,
    onOpenChange: (isOpen) => !isOpen && onRequestClose(),
    middleware: [
      autoPlacement({ allowedPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end'] }),
      size({
        apply({ availableHeight, elements }) {
          elements.floating.style.maxHeight = `${availableHeight - 12}px`;
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown', bubbles: false });
  const interactions = useInteractions([dismiss]);

  return (
    <>
      <FloatingPortal>
        <FloatingOverlay lockScroll>
          <Box
            data-testid="cell-dropdown"
            ref={refs.setFloating}
            style={floatingStyles}
            {...interactions.getFloatingProps({
              onKeyDown: trapEnter ? trapEnterHandler : undefined,
            })}
            className={dropdownContainer}
          >
            {children}
          </Box>
        </FloatingOverlay>
      </FloatingPortal>
      <div ref={refs.setReference} {...interactions.getReferenceProps()} className={dropdownAnchor} />
    </>
  );
}
