import cx from 'clsx';
import { FocusEvent, KeyboardEvent, ReactElement, useRef } from 'react';
import { textEditorClassname } from './styles.css';
import { mergeRefs, parseAndFormatDecimal } from 'utilities';
import { EditorProps } from './types';

export function autoFocusAndSelect(input: HTMLInputElement | null): void {
  input?.focus();
  input?.select();
}

export function textEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  className,
  field = column.key,
}: EditorProps<TRow, TSummaryRow>): ReactElement {
  return (
    <input
      ref={autoFocusAndSelect}
      className={cx(textEditorClassname, className)}
      value={(row[field as keyof TRow] as unknown as string) || ''}
      onChange={(event) => onRowChange({ ...row, [field]: event.target.value })}
      onBlur={() => onClose(true, false)}
    />
  );
}

type DecimalEditorProps<TRow, TSummaryRow> = EditorProps<TRow, TSummaryRow> & {
  precision?: number;
  normalize?: boolean;
};

export function DecimalEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  field = column.key,
  precision = 2,
  normalize = false,
  className,
}: DecimalEditorProps<TRow, TSummaryRow>): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  const currentValue = row[field as keyof TRow] as unknown as string | null;

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      onClose(false);
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      const newVal = parseAndFormatDecimal(inputRef.current?.value || '', normalize, precision);
      if (newVal !== currentValue) {
        onRowChange({ ...row, [field]: newVal }, true);
      }
      onClose(false);
    }
  }

  function onBlur(_e: FocusEvent<HTMLInputElement>): void {
    const newVal = parseAndFormatDecimal(inputRef.current?.value || '', normalize, precision);

    if (newVal !== currentValue) {
      onRowChange({ ...row, [field]: newVal }, true);
    }
    onClose(false);
  }

  return (
    <input
      ref={mergeRefs([inputRef, autoFocusAndSelect as any])}
      className={cx(textEditorClassname, className)}
      defaultValue={currentValue || ''}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
}
