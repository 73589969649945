import { forwardRef, InputHTMLAttributes } from 'react';
import cx from 'clsx';
import { wrapper, input, sentinel } from './Checkbox.css';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  size?: 'sm' | 'md' | 'lg';
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ size, disabled, checked, onChange, id, name, value, className }, ref) => {
    return (
      <div
        className={cx(
          className,
          wrapper({
            size,
            disabled,
            checked,
          }),
        )}
      >
        <input
          type="checkbox"
          className={input}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          id={id}
          name={name}
          value={value}
          ref={ref}
        />
        <span className={sentinel}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="100%" height="100%">
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
          </svg>
        </span>
      </div>
    );
  },
);
