import { ReactElement, ReactNode } from 'react';
import { colors } from './style.css';
import type { SmartOption } from '../../../types';
import { MicroBadgeContainer, MicroBadgeValue } from 'components/MicroBadge';

const symbols = {
  RATE: '%',
  UNIT: '×',
} as const;

export function Label({ index, rate, application, direction }: Omit<SmartOption, 'naive'>): ReactElement {
  const symbol = application ? symbols[application] : '';

  let value: ReactNode = <MicroBadgeValue>—{symbol}</MicroBadgeValue>;
  switch (direction) {
    case 'CLIENT':
      value = (
        <MicroBadgeValue style={{ background: colors.CLIENT[index % 3] }}>
          {rate.client || '—'}
          {symbol}
        </MicroBadgeValue>
      );
      break;
    case 'AGENCY':
      value = (
        <MicroBadgeValue style={{ background: colors.AGENCY[index % 3] }}>
          {rate.provider || '—'}
          {symbol}
        </MicroBadgeValue>
      );
      break;
    case 'BOTH':
      value = (
        <>
          <MicroBadgeValue style={{ background: colors.CLIENT[index % 3] }}>
            {rate.client || '—'}
            {symbol}
          </MicroBadgeValue>
          <MicroBadgeValue style={{ background: colors.AGENCY[index % 3] }}>
            {rate.provider || '—'}
            {symbol}
          </MicroBadgeValue>
        </>
      );
      break;
  }

  return <MicroBadgeContainer>{value}</MicroBadgeContainer>;
}
