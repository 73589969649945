import { Icon, type IconProp } from '../../Icon';
import { Button, type ButtonProps } from '@ff-it/ui';
import type { ReactElement } from 'react';

export function AddButton({
  children,
  variant = 'outline-primary',
  icon = 'plus',
  ...props
}: Partial<ButtonProps> & { icon?: IconProp }): ReactElement {
  return (
    <Button variant={variant} type="button" className="border-0" size="sm" {...props}>
      <Icon icon={icon} /> {children}
    </Button>
  );
}
