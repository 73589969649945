import type { RequestFailure, RequestResult } from '@ff-it/api';
import type { ButtonVariant } from '@ff-it/ui';
import Big from 'big.js';
import { format, formatDistanceStrict, isValid, parseISO } from 'date-fns';
import { type LegacyRef, type MutableRefObject, type ReactElement, type RefCallback, createElement } from 'react';
import { toast } from 'react-toastify';
import type { SWRHook } from 'swr';
export * from './decimal';

export type Validator = (value: any) => string | undefined;

export function composeValidators(...validators: Validator[]): Validator {
  return (value: any): string | undefined =>
    validators.reduce((error: string | undefined, validator) => error || validator(value), undefined);
}

export function identity<T = any>(value: T): T {
  return value;
}

export function parseBig(inp?: string | number | null): Big {
  if (inp) {
    try {
      return Big(inp);
    } catch (e) {}
  }
  return Big(0);
}

export function decimalPlaces(x: Big): number {
  // @TODO don't cast to string use exp
  const str = x.toFixed();
  const index = str.indexOf('.');
  if (index >= 0) {
    return str.length - index - 1;
  } else {
    return 0;
  }
}

export function fmtMonth(date: string): string {
  return format(parseISO(date), 'MMM. yyyy');
}

export function formatDate(isoDate: string): string {
  return format(parseISO(isoDate), 'dd.MM.yyyy');
}

export function formatDateTime(isoDate: string): string {
  return format(parseISO(isoDate), 'dd.MM.yyyy HH:mm');
}

export function fmtPeriodDistance({ date_from, date_to }: { date_from: string; date_to: string }): string {
  return formatDistanceStrict(parseISO(date_from), parseISO(date_to));
}

const zeroRegex = /^0\.?0*$/;

export function isZero(v: string): boolean {
  return v.match(zeroRegex) !== null;
}
export function sepFormat(v?: string | null, hideZero = false, hideTrailing = false): string {
  if (v === null || v === undefined || (hideZero && isZero(v))) {
    return '';
  }
  const arr = v.split('.');
  arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const res = arr.join('.');
  if (hideTrailing) {
    return res.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)?.[0] || '';
  }
  return res;
}

export function fmt(num?: Big, isPercentage = false, sepGroup = true): string {
  if (!num) {
    return '—';
  }

  let val = num.toFixed(2);
  if (sepGroup) {
    val = sepFormat(val);
  }

  return `${val}${isPercentage ? '%' : ''}`;
}

const newlineRegex = /(\r\n|\r|\n)/g;

export function nl2br(inp: string): Array<string | ReactElement> {
  return inp.split(newlineRegex).map((line: string, index: number) => {
    if (line.match(newlineRegex)) {
      return createElement('br', { key: index });
    }
    return line;
  });
}

export const isEmpty = (value: any): boolean => value === undefined || value === null || value === '';

export const required = (value: any): string | undefined => (isEmpty(value) ? 'This field is required' : undefined);
export const requiredAtLeastOne: Validator = (value) =>
  value && value.length > 0 ? undefined : 'At least one value required';

export const gt =
  (than: Big) =>
  (value: any): string | undefined =>
    !isEmpty(value) ? (!parseBig(value).gt(than) ? `Has to be greater than ${than.toFixed(2)}` : undefined) : undefined;

export const positive = (value: any): string | undefined =>
  required(value) || (parseBig(value).lte(0) ? `Has to be greater than zero` : undefined);

export function prompStale(message = ''): void {
  if (window.confirm(`${message}\nYou seem to be editing stale data. Refresh?`)) {
    window.location.reload();
  }
}

export function messageFromFailure(res: RequestFailure<any>): string {
  let message: string;
  if (typeof res.data === 'string') {
    message = res.data;
  } else if (Array.isArray(res.data)) {
    message = res.data.join(' ,');
  } else {
    if (res.data.detail) {
      message = res.data.detail;
    } else {
      // FIXME
      message = JSON.stringify(res.data);
    }
  }
  return message;
}

export function checkActionError(res: RequestFailure<any>): boolean {
  if ([400, 403, 405, 409, 412].includes(res.status)) {
    const message = messageFromFailure(res);

    if (res.status === 412) {
      prompStale(message);
    } else {
      toast.error(message);
    }
    return true;
  }
  return false;
}

export function maybeActionErrorOrThrow(res: RequestResult<any, any>): void {
  if (!res.ok) {
    actionErrorOrThrow(res);
  }
}

export function actionErrorOrThrow(res: RequestFailure<any>): void {
  if (!checkActionError(res)) {
    throw res.error;
  }
}

export function actionErrorAndThrow(res: RequestFailure<any>): never {
  checkActionError(res);
  throw res.error;
}

// FIXME
export function parseDate(str: string | undefined): Date | undefined {
  if (!str) {
    return undefined;
  }
  if (str.match(/\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/)) {
    const parsed = parseISO(str);
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return undefined;
}

const MAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function isValidEmail(email: string): boolean {
  return MAIL_REGEXP.test(email);
}

export const cmpStrings = new Intl.Collator().compare;

export default class Mutex {
  private _locking: Promise<void>;
  private _locks: number;
  constructor() {
    this._locking = Promise.resolve();
    this._locks = 0;
  }

  public isLocked(): boolean {
    return this._locks > 0;
  }

  //fixme
  public lock(): Promise<() => void> {
    this._locks += 1;

    let unlockNext: any;

    const willLock = new Promise<void>((resolve) => {
      unlockNext = () => {
        this._locks -= 1;

        resolve();
      };
    });

    const willUnlock = this._locking.then(() => unlockNext);

    this._locking = this._locking.then(() => willLock);

    return willUnlock;
  }
}

export const buttonVariantToIconColor: Partial<Record<ButtonVariant, string>> = {
  success: 'text-success',
  danger: 'text-danger',
  'outline-danger': 'text-danger',
  'outline-success': 'text-success',
};

export const coerceEmptyArrayToNull = (v: any): any => {
  return v && v.length === 0 ? null : v;
};

export function partition<T>(arr: Array<T>, predicate: (val: T) => boolean): [Array<T>, Array<T>] {
  const partitioned: [Array<T>, Array<T>] = [[], []];
  arr.forEach((val: T) => {
    const partitionIndex: 0 | 1 = predicate(val) ? 0 : 1;
    partitioned[partitionIndex].push(val);
  });
  return partitioned;
}

export const replaceItemAtIndex = <T>(arr: T[], index: number, newValue: T): T[] => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = <T>(arr: T[], index: number): T[] => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export function mergeRefs<T = any>(refs: Array<MutableRefObject<T> | LegacyRef<T> | undefined | null>): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const disableSWRCache = (useSWRNext: SWRHook) => {
  return (key: any, fetcher: any, config: any) => {
    const swr = useSWRNext(key, fetcher, config);
    const { data, isValidating } = swr;
    return Object.assign({}, swr, {
      data: isValidating ? undefined : data,
    });
  };
};

export function parseAttachmentFilename(headers: Headers): string | undefined {
  let filename;
  const attachmentName = headers
    .get('Content-Disposition')
    ?.split(';')
    .find((n) => n.includes('filename='))
    ?.replace('filename=', '')
    .replaceAll('"', '')
    .trim();

  if (attachmentName) {
    filename = decodeURIComponent(attachmentName);
  }
  return filename;
}

export const coerceEmptyToNull = {
  parse: (value: string) => {
    if (value === '') return null;
    return value;
  },
  format: (value: string | null) => {
    if (!value) {
      return '';
    }
    return value;
  },
};
