import { Icon, type IconProp } from 'components/Icon';
import cx from 'clsx';
import { forwardRef, HTMLAttributes, ReactElement } from 'react';
import { badge, badgePlacement } from './style.css';

type CellBadgeProps = {
  // children: ReactNode;
  placement: keyof typeof badgePlacement;
  icon: IconProp;
  testId?: string;
  title?: string;
  className?: string;
} & HTMLAttributes<HTMLSpanElement>;

export const CellBagde = forwardRef<HTMLSpanElement, CellBadgeProps>(
  ({ icon, testId, placement, title, className, children, ...rest }: CellBadgeProps, ref) => {
    return (
      <span title={title} ref={ref} className={cx(badge, badgePlacement[placement], className)} {...rest}>
        <Icon icon={icon} data-testid={testId} />
        {/*  children to get popover render */}
        {children}
      </span>
    );
  },
);

export function DotBadge(): ReactElement {
  return <CellBagde placement="top-start" icon="circle-small" testId="dot-badge" title="Has default value" />;
}
