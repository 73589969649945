import { Box } from '@ff-it/ui';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import type { PlanSelection, PlanState } from '../types';
import { SelectionControls } from './SelectionControls';
import type { BlockDetails } from 'modules/campaign/block/types';
import { AddRowButton } from './AddRowButton';
import { useEntity } from 'components';
import { BlockNotice } from 'modules/campaign/block/BlockNotice';
import { usePlanController } from '../usePlanController';
import { SummaryButton } from './SummaryButton';
import { ImportExportButton } from './ImportExportButton';
import { SettingsButton } from './SettingsButton';

type ToolbarProps = {
  planState: PlanState;
  planSelection: PlanSelection;
  setPlanSelection: Dispatch<SetStateAction<PlanSelection>>;
};
export function Toolbar({ planState, planSelection, setPlanSelection }: ToolbarProps): ReactElement {
  const { rows } = planState;
  // NOTE: we could pass controller directly, but would need to uncouple creation from ctx provider
  const controller = usePlanController();
  const { item: block } = useEntity<BlockDetails>();
  const hasLockedRows = rows.find((row) => row.state.is_locked) !== undefined;

  return (
    <Box marginBottom="xs" display="flex">
      <SelectionControls
        controller={controller}
        rows={rows}
        planSelection={planSelection}
        setPlanSelection={setPlanSelection}
      />
      <Box marginX="sm" flexGrow={1}>
        <BlockNotice {...block} className="d-inline-block" />
      </Box>

      <div className="btn-toolbar">
        <div className="btn-group btn-group-sm">
          <SettingsButton controller={controller} planState={planState} type={block.type} />
          <SummaryButton rows={rows} />
          <ImportExportButton controller={controller} planState={planState} hasLockedRows={hasLockedRows} />
        </div>
        <AddRowButton controller={controller} />
      </div>
    </Box>
  );
}
