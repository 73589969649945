import { ReactElement } from 'react';
import type { ActivityType, ProductKind } from 'types';
import { EmbeddedProduct } from './types';
import { SelectField, SelectFieldProps } from '@ff-it/form';
import { useOptions, ResponseHandler } from 'components';
import { RequestSuccess } from '@ff-it/api';
import { ProductTitle } from './ProductTitle';
import { StylesConfig, selectStyle } from '@ff-it/ui';

type ProductFieldProps = {
  type: ActivityType;
  kind?: ProductKind | ProductKind[];
  provider?: number;
  department?: number;
  pageSize?: number;
} & Omit<SelectFieldProps<EmbeddedProduct>, 'loadOptions' | 'options'>;

type DepartmentGroup = {
  label: string;
  options: EmbeddedProduct[];
};

const groupDepartments: ResponseHandler = (result: RequestSuccess<any>): any[] => {
  const data: EmbeddedProduct[] = result.data.results;
  // products are returend ordered by similary (including department)
  const departments = new Map<number, DepartmentGroup>();

  for (let i = 0; i < data.length; i++) {
    const product = data[i];
    const department = departments.get(product.department.id);
    if (!department) {
      const department = {
        label: product.department.name,
        options: [product],
      };
      departments.set(product.department.id, department);
    } else {
      department.options.push(product);
    }
  }

  return Array.from(departments.values());
};

export const styles: StylesConfig<any, any, any> = {
  ...selectStyle,
  groupHeading: (_base) => ({
    position: 'sticky',
    top: 0,
    padding: '0.3rem 1rem',
    backgroundColor: '#f2f2f2',
    fontSize: '14px',
  }),
  group: (_base) => ({
    borderTop: '1px solid #eee',
  }),
  option: (base, ctx) => ({
    ...base,
    padding: '0.5rem 1.25rem',
    fontStyle: ctx.data.name === '' ? 'italic' : undefined,
  }),
  menu: (base) => ({
    ...base,
    marginTop: undefined,
    marginBottom: undefined,
  }),
};

export const productFieldOptions = {
  getOptionLabel: ({ name }: EmbeddedProduct) => name || 'Generic product',
  formatOptionLabel: (product: EmbeddedProduct, { context }: { context: 'menu' | 'value' }) => {
    return context === 'menu' ? (
      <span data-testid={`product-${product.id}`}>{product.name || 'Generic product'}</span>
    ) : (
      <ProductTitle product={product} />
    );
  },
  getOptionValue: (a: EmbeddedProduct) => a.id.toString(),
  styles,
};

export const departmentProductFieldOptions = {
  getOptionLabel: productFieldOptions.getOptionLabel,
  getOptionValue: productFieldOptions.getOptionValue,
};

export function ProductField({
  type,
  kind,
  provider,
  pageSize,
  department,
  ...props
}: ProductFieldProps): ReactElement {
  const filter: Record<string, unknown> = { type, provider, department, disabled: false };

  if (kind) {
    if (Array.isArray(kind)) {
      filter['kind__in'] = kind.join(',');
    } else {
      filter['kind'] = kind;
    }
  }

  const loadOptions = useOptions<any>('supplier/products/', filter, { responseHandler: groupDepartments, pageSize });

  return <SelectField<EmbeddedProduct> {...props} loadOptions={loadOptions} {...productFieldOptions} />;
}
