import { ReactElement, useCallback } from 'react';
import { Button, Select } from '@ff-it/ui';
import { useOptions } from './fields';
import { useNavigate } from 'react-router';
import { Icon } from './Icon';
import { auth, useSession } from 'services';
import { SessionUser } from 'modules/core/types';

const selectProps = {
  placeholder: 'Impersonation...',
  isClearable: true,
  getOptionLabel: (a: SessionUser) => a.email,
  getOptionValue: (a: SessionUser) => a.id.toString(),
};

export function Impersonation(): ReactElement | null {
  const navigate = useNavigate();
  const { user, impersonation } = useSession();

  const loadOptions = useOptions<any>('auth/users/impersonate/');

  const onChange = useCallback(
    (v: any) => {
      auth.impersonation = v;
      navigate('/');
    },
    [navigate],
  );

  // Superuser on impersonate permission in any agency
  if (
    !(
      user?.is_superuser ||
      user?.membership.find((scope) => scope?.kind === 'AGENCY' && scope.permissions.indexOf('core.impersonate') !== -1)
    )
  ) {
    return null;
  }
  const hasValue = !!impersonation;

  return (
    <div className="form-inline ml-1">
      <Select
        size="sm"
        loadOptions={loadOptions}
        value={impersonation}
        onChange={onChange}
        {...selectProps}
        name="impersonate_user"
        id="impersonate_user"
        disabled={hasValue}
        isClearable={false}
      />
      {hasValue && (
        <Button variant="outline-secondary" size="sm" className="ml-1 px-1 border-0" onClick={() => onChange(null)}>
          <Icon icon="xmark" />
        </Button>
      )}
    </div>
  );
}
