import { Box, type BoxProps } from '@ff-it/ui';
import { Icon, type IconProp } from 'components/Icon';
import type { ReactElement, ReactNode } from 'react';
import { type RequireExactlyOne } from 'type-fest';
import { container } from './IconBadge.css';

export type IconBadgeProps = Omit<BoxProps, 'children'> &
  RequireExactlyOne<{
    icon: IconProp | null;
    children: ReactNode;
  }> & { testId?: string };

export type IconBadgeBaseProps = Pick<IconBadgeProps, 'title' | 'background' | 'color' | 'testId'> & {
  icon: IconProp | null;
};

export function IconBadge({ children, icon, testId, ...rest }: IconBadgeProps): ReactElement {
  return (
    <Box className={container} data-testid={testId} {...rest}>
      {icon ? <Icon icon={icon} /> : children}
    </Box>
  );
}
