import { CSSProperties, ReactNode } from 'react';
import { containerClassName, setContainer, valueClassName } from './style.css';

export function MicroBadgeContainer({ children }: { children: ReactNode }) {
  return <span className={containerClassName}>{children}</span>;
}

export function MicroBadgeValue({ children, style }: { children: ReactNode; style?: CSSProperties }) {
  return (
    <span className={valueClassName} style={style}>
      {children}
    </span>
  );
}

export function MicroBadge({ children }: { children: ReactNode }) {
  return (
    <MicroBadgeContainer>
      <MicroBadgeValue>{children}</MicroBadgeValue>
    </MicroBadgeContainer>
  );
}

export function MicroBadgeSet({ children }: { children: ReactNode }) {
  return <div className={setContainer}>{children}</div>;
}
