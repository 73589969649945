import type { ReactNode } from 'react';
import { cancelExport, type PlanStrategy } from './type';
import { BlockWidget, DialogForm } from 'components';
import { api } from 'services';
import { ListBlock } from '../types';
import { openModal } from '@ff-it/ui';
import { CheckField, CheckGroupField } from '@ff-it/form';
import { PlanState } from '../views/Plan/types';
import {
  FormatColumn,
  CappingColumn,
  KPIColumn,
  PlacementColumn,
  TgColumn,
  WebDeviceColumn,
  LanguageColumn,
  EstImpressionsColumn,
  EstClicksColumn,
  CTRColumn,
} from '../views/Plan/Grid/columns';

const intialValues = { blocks: [], show_groups: true };

export const RTB: PlanStrategy = {
  KEY: 'RTB',
  TITLE: 'WEB plan',
  copyRow: (row) => {
    // comment?
    switch (row.kind) {
      case 'SMART':
        return {
          kind: row.kind,
          supplier: row.supplier,
          rate: row.rate,
          // apply_to: []
        };
      case 'SERVICE':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          gross_price: row.gross_price,
          provider_price: row.provider_price,
          naive: row.naive,
        };
      case 'MEDIA':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          gross_price: row.gross_price,
          client_price: row.client_price,
          provider_price: row.provider_price,
          naive: row.naive,
          // RTB fields
          language: row.language,
          web_device: row.web_device,
          placement: row.placement,
          format: row.format,
          // FIXME: fix in serializer or model? why do we return nulls to begin with
          tg: row.tg || '',
          kpi: row.kpi || '',
          capping: row.capping || '',
          // estimations
          ctr: row.ctr,
          est_impressions: row.est_impressions,
          est_clicks: row.est_clicks,
          // reach
          reach_composition: row.reach_composition,
          reach_frequency: row.reach_frequency,
        };
      default:
        throw Error(`Unexpected kind: ${row.kind}`);
    }
  },
  async exportPlanOptions({ id, campaign_id, groups }: PlanState) {
    const res = await api.request<ListBlock[], unknown>({
      method: 'GET',
      url: 'campaign/blocks/',
      queryParams: {
        campaign: campaign_id,
        exclude: id,
        plan_strategy: 'RTB',
        ordering: 'order',
      },
    });

    const siblingOptions = (res.ok ? res.data : []).map((block) => ({
      value: block.id.toString(),
      label: (<BlockWidget {...block} flat />) as ReactNode,
    }));

    let exportOptions = undefined;
    if (siblingOptions.length || groups.length) {
      exportOptions = await openModal<{ blocks: string[] } | undefined>(
        (props) => (
          <DialogForm {...props} dialogHeader="Plan export" initialValues={intialValues}>
            <CheckGroupField name="blocks" options={siblingOptions} label="Include sibling plans" />
            {groups.length > 0 && (
              <CheckField name="show_groups" label="Show groups" fieldLabel="Render group rows" variant="switch" />
            )}
          </DialogForm>
        ),
        { testId: 'rtb-export-options' },
      );
      if (typeof exportOptions === 'undefined') {
        return cancelExport;
      }
      return exportOptions;
    }
  },
  columns: [
    PlacementColumn,
    LanguageColumn,
    WebDeviceColumn,
    FormatColumn,
    TgColumn,
    KPIColumn,
    CappingColumn,
    EstImpressionsColumn,
    EstClicksColumn,
    CTRColumn,
  ],
  canExportMediaReservation: true,
};
